/* eslint-disable */

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// import TimelineList from "examples/Timeline/TimelineList";
// import TimelineItem from "examples/Timeline/TimelineItem";

// Data
// import timelineData from "layouts/pages/projects/timeline/data/timelineData";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

import React from "react";
import { getAuthHeaders } from "helpers";
import { CircularProgress } from "@mui/material";

import axios from "../../../../../axios";
import moment from "moment";

class Logs extends React.Component {
  applicationID = null;

  constructor(props) {
    super(props);
    this.state = { loading: false, data: null };
    const { id } = this.props;
    this.applicationID = id;
  }

  componentDidMount() {
    this.getLogData();
  }

  getLogData = async () => {
    try {
      const res = await axios.get(
        `/v2/application/${this.applicationID}/logs`,
        getAuthHeaders()
      );
      console.log("res.data logs", res.data);
      var dates = null;

      if (res.data.length > 0) {
        dates = {};
        res.data.forEach((element) => {
          const date = moment(element.created).format("YYYY-MM-DD");
          if (!dates[date]) dates[date] = [];
          dates[date].push({
            name: element.name,
            email: element.email,
            created: element.created,
            details: element.details?.replace("null", "NO_VALUE"),
          });
        });
      }
      console.log("dates");
      console.log(dates);
      this.setState({ data: dates, loading: false });
    } catch (error) {
      console.log("error", error);
      this.setState({ loading: false });
    }
  };

  render() {
    // const renderTimelineItems = timelineData.map(
    //   ({ color, icon, title, dateTime, description, badges, lastItem }) => (
    //     <TimelineItem
    //       key={title + color}
    //       color={color}
    //       icon={icon}
    //       title={title}
    //       dateTime={dateTime}
    //       description={description}
    //       badges={badges}
    //       lastItem={lastItem}
    //     />
    //   )
    // );
    const { loading } = this.state;
    return (
      <MDBox my={3}>
        <Grid container justifyContent="center" alignItems={"center"}>
          {loading ? (
            <CircularProgress />
          ) : (
            <Grid item xs={12} md={8}>
              <Timeline position="right">
                {!this.state.loading &&
                  this.state.data != null &&
                  Object.keys(this.state.data).map((date, index) => (
                    <>
                      <Grid
                        container
                        sx={{
                          marginLeft: { xs: 6, md: 0 },
                          justifyContent: { md: "center", xs: "start" },
                        }}
                      >
                        <MDBox
                          variant="gradient"
                          bgColor="secondary"
                          color="light"
                          borderRadius={8}
                          style={{
                            maxWidth: "100%",
                            textAlign: "center",
                            marginBottom: 8,
                            marginTop: 8,
                            padding: "4px 8px",
                          }}
                        >
                          {moment(date).format("DD MMM YYYY")}
                        </MDBox>
                      </Grid>
                      {this.state.data[date].map((item) => (
                        <TimelineItem>
                          <TimelineOppositeContent
                            sx={{ maxWidth: { xs: 100, md: "100%" } }}
                            style={{ fontSize: "small" }}
                            color="text.secondary"
                          >
                            {moment(item.created).format("hh:mm A")}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                          </TimelineSeparator>

                          <TimelineContent style={{ fontSize: "small" }}>
                            <div
                              dangerouslySetInnerHTML={{ __html: item.details }}
                            />{" "}
                            <MDBox color="secondary">By {item.name}</MDBox>
                          </TimelineContent>
                        </TimelineItem>
                      ))}
                    </>
                  ))}
                <Grid
                  container
                  sx={{
                    marginLeft: { xs: 6, md: 0 },
                    justifyContent: { md: "center", xs: "start" },
                  }}
                >
                  <MDBox
                    variant="gradient"
                    bgColor="secondary"
                    color="light"
                    borderRadius={8}
                    style={{
                      maxWidth: "100%",
                      textAlign: "center",
                      marginBottom: 8,
                      marginTop: 8,
                      padding: "4px 8px",
                    }}
                  >
                    {moment(this.props.created).format("DD MMM YYYY")}
                  </MDBox>
                </Grid>
                <TimelineItem>
                  <TimelineOppositeContent
                    sx={{ maxWidth: { xs: 100, md: "100%" } }}
                    style={{ fontSize: "small" }}
                    color="text.secondary"
                  >
                    {moment(this.props.created).format("hh:mm A")}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    {/* <TimelineConnector /> */}
                  </TimelineSeparator>

                  <TimelineContent style={{ fontSize: "small" }}>
                    Application Created
                  </TimelineContent>
                </TimelineItem>
              </Timeline>

              {/* <TimelineList title="Logs for Hassan Ahmed's Application">
                  {renderTimelineItems}
                </TimelineList> */}
            </Grid>
          )}
        </Grid>
      </MDBox>
    );
  }
}

export default Logs;
