/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";

// @material-ui core components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import { CircularProgress,FormControl,MenuItem,Select,InputLabel, Card, Divider, List, ListItemButton, ListItemText, Collapse, ListItem, Dialog, Slide, AppBar, Toolbar, IconButton } from "@mui/material";
import moment from "moment";
import {getTerms} from '../../../../../constants';
import { ExpandLess, ExpandMore,  Close as CloseIcon,  Receipt as ReceiptIcon,

} from "@mui/icons-material";
import React from "react";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class  CreditInfo extends React.Component{
  state={
    equifaxDialogOpen:false,
    equifaxInfo:this.props.data.equifaxInfo,
    openedCollapibles:[]

  }

  removeLeadingZeros = (num,isCurrency=false)=> {
    // traverse the entire string
    for (var i = 0; i < num.length; i++) {
 
        // check for the first non-zero character
        if (num.charAt(i) != '0') {
            // return the remaining string
            let res = num.substr(i);
            return isCurrency ? parseFloat(res).toLocaleString('en-US',{style:'currency',currency:'USD',minimumFractionDigits:0}):res;
        }
    }
 
    // If the entire string is traversed
    // that means it didn't have a single
    // non-zero character, hence return "0"
    return "0";
}

isInLastSixMonths = (dateToCheck) => {
  // console.log(dateToCheck,':',moment().diff(moment(dateToCheck), "days"))
  return moment().diff(moment(dateToCheck), "days") < 30*6
}


handleCollapse = ({type,currentlyOpen})=>{
  console.log(type,currentlyOpen)
  let openedCollapibles = [...this.state.openedCollapibles];
  if(currentlyOpen)
    openedCollapibles = openedCollapibles.filter((item)=>item!=type)
  else
  openedCollapibles.push(type);
  this.setState({openedCollapibles});
}


render(){

 
  const {handleChange, handleLeaseChange,data, handleApproval, saving}=this.props;

  
  
  return (
    <>
      <MDBox p={3} display='flex' justifyContent='space-between'>
        <MDTypography variant="h5">Credit & Finance Info</MDTypography>
        <MDButton
                          startIcon={<ReceiptIcon />}
                          variant="contained"
                          sx={{ marginLeft: 0 }}
                          onClick={() => this.setState({ equifaxDialogOpen: true })}
                          size="large"
                          color="primary"
                        >
                          View Equifax Info
                        </MDButton>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              required
              value={data?.creditScore}
              readonly
              name="creditScore"
              label="Credit Score"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              required
              value={data?.creditRemarks}
              readonly
              name="creditRemarks"
              label="Credit Remarks"
              placeholder=""
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormField
              required
              value={'Paused'}
              readonly
              name="approve"
              label="Auto Approval"
              placeholder=""
            />
          </Grid> */}
          <Grid item xs={12} sm={6}>
          <FormControl
              required
              variant="standard"
              sx={{ pt: 1, minWidth: 120 }}
              fullWidth
            >
              <InputLabel id="province-label">Lease Term</InputLabel>
              <Select
                fullWidth
                onChange={handleLeaseChange}
                labelId="leaseTerm-label"
                name="leaseTerm"
                label="Lease Term"
                defaultValue={data.leaseTerm}
              >
                {getTerms().map((term) => (
                  <MenuItem
                    value={term.value}
                  >{`${term.name} at ${term.interestRate}%`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              required
              defaultValue={data.financeAmount}
              onBlur={handleChange}
              name="financeAmount"
              label="Finance Amount"
              placeholder=""
              hintText="The requested amount!"
            />
          </Grid>
          <Grid item xs={12}>
            <FormField
              required
              onBlur={handleChange}
              defaultValue={data.comments}
              name="comments"
              label="Comments"
              placeholder=""
              helperText="Comments are for internal use"
            />
          </Grid>
          <Grid item xs={12}>
          
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent={data.status != null ? "center" : "flex-end"}>
              {
                // eslint-disable-next-line no-nested-ternary
                saving ? (
                  <CircularProgress />
                ) : data.status != null && data.statusChangeTime ? (
                  <MDBox color={data.status ? "success" : "error"}>
                    {data.status ? "Approved" : "Rejected"} at{" "}
                    {moment(data.statusChangeTime).format("DD MMM YYYY , hh:mm A")} by{" "}
                    {data.statusChangeName} - {data.statusChangeEmail}
                  </MDBox>
                ) : (
                  <>
                    <MDButton
                      onClick={() => handleApproval(false)}
                      style={{ marginRight: 8 }}
                      color="error"
                      variant="outlined"
                    >
                      Reject
                    </MDButton>
                    <MDButton
                      onClick={() => handleApproval(true)}
                      variant="outlined"
                      color="success"
                    >
                      Approve
                    </MDButton>
                  </>
                )
              }
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
      <Dialog
          fullScreen
          open={this.state.equifaxDialogOpen}
          onClose={() => this.setState({ equifaxDialogOpen: false })}
          TransitionComponent={Transition}
        >
          <AppBar color="info" sx={{ position: "relative" }}>
            <Toolbar
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h3>Equifax Info</h3>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => this.setState({ equifaxDialogOpen: false })}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          {this.state?.equifaxInfo && <div style={{margin:8,padding:8,background:'#f6f6f6'}}>
          
          
          {this.state?.equifaxInfo?.Error && <Grid style={{margin:8,padding:8}}  container justifyContent={'center'}><Grid item xs={12}  md={6}>
            <Card style={{padding:16}}>
            <h3>Error retreving file from Equifax</h3>
              <div style={{margin:16}}>
                {this.state?.equifaxInfo?.Error.map((errorType,index)=>{
                   return <div style={{padding:16}}>
                     <b>({index+1}) Error</b>
                     <p>{errorType.Description[0]._text}</p>
                    <p>{errorType.ActionDescription[0]._text}</p>
                    </div>
                })}
              </div>
            </Card>
          </Grid>
          </Grid>
          }
          {this.state?.equifaxInfo?.CNConsumerStatements && <Grid style={{margin:8,padding:8}}  container justifyContent={'center'}><Grid item xs={12}  md={6}>
            <Card style={{padding:16}}>
            <h3>NOTE</h3>
              <div style={{margin:16}}>
                {this.state?.equifaxInfo?.CNConsumerStatements[0].CNConsumerStatement.map((message,index)=>{
                   return <div style={{padding:16}}>
                     <b>Date Reported : {moment(message.DateReported[0]._text[0]).format('DD MMM YYYY')} </b>
                    <p>{message.Statement[0]._text[0]}</p>
                    </div>
                })}
              </div>
            </Card>
          </Grid>
          </Grid>
          }

          {this.state?.equifaxInfo?.CNScores && <Grid style={{margin:8,padding:8}}  container justifyContent={'center'}><Grid item xs={12} md={6}>
            <Card style={{padding:16}}>
            <h3>Credit Score Summary</h3>
        {this.state?.equifaxInfo?.CNScores[0]?.CNScore[0]?.Result ?   <b><p style={{padding:8}}>{this.removeLeadingZeros(this.state?.equifaxInfo?.CNScores[0]?.CNScore[0]?.Result[0]?.Value[0]._text[0])}</p></b>
  :'Did not receive score'}
          {this.state?.equifaxInfo?.CNScores[0]?.CNScore[0]?.Reasons && this.state?.equifaxInfo?.CNScores[0]?.CNScore[0]?.Reasons[0]?.Reason?.map(reason=>{
            return <p style={{padding:8}}>{' '} - {reason._attributes.description}</p>
          })}
            </Card>
          </Grid>
          </Grid>
          }

{this.state?.equifaxInfo?.CNLocalInquiries && <Grid style={{margin:8,padding:8}}  container justifyContent={'center'}><Grid item xs={12} md={6} style={{marginTop:16}}>
          <Card style={{padding:16,}}>
            <h3>Local Inquiries</h3>

           <ListItemButton onClick={()=>this.setState({localInquiryCollapse:!this.state.localInquiryCollapse})}>
             <ListItemText primary={`${this.state.equifaxInfo.CNLocalInquiries[0].CNLocalInquiry.filter((item)=>{
              return this.isInLastSixMonths(item._attributes.date)
            }).length} inquiries in last 6 months`}/>
            {this.state.localInquiryCollapse ? <ExpandLess /> : <ExpandMore />}
           </ListItemButton>
           <Collapse in={this.state.localInquiryCollapse} timeout="auto" unmountOnExit style={{margin:'8px 16px'}}>
              <List style={{marginTop:16,fontSize:16}}>
          {this.state?.equifaxInfo?.CNLocalInquiries[0]?.CNLocalInquiry.filter((item)=>{
              return this.isInLastSixMonths(item._attributes.date)
            }).map((inquiry,index)=>{
            return  <>
            <ListItem >
              <ListItemText 
              primary={inquiry.CustomerId?inquiry.CustomerId[0].Name[0]._text:'Not Disclosed'}
             
              />
              <span>{inquiry._attributes? moment(inquiry._attributes.date).format('DD MMM YYYY'):'-'}</span>
                </ListItem>
                <Divider />
                </>
          })}
              </List>
             </Collapse>
         
            </Card>
          </Grid>
          </Grid>
          }
      
          {this.state?.equifaxInfo?.CNTrades && <Grid style={{margin:8,padding:8}}  container justifyContent={'center'}><Grid item xs={12} md={6} style={{marginTop:16}}>
          <Card style={{padding:16,}}>
            <h3>Trades/ Accounts</h3>
           
          <List style={{marginTop:16}}>
          {this.state?.equifaxInfo?.CNTrades[0]?.CNTrade.map((trade,index)=>{
            let open = this.state.openedCollapibles.indexOf('trade_'+index)!==-1;
            let accountClosed;
            if(trade.Narratives){
              accountClosed=false;
              trade.Narratives[0].Narrative.forEach(narrative=>{
                if(narrative._attributes.description.toLowerCase().indexOf('closed')!==-1 || narrative._attributes.description.toLowerCase().indexOf('inactive')!==-1 || narrative._attributes.description.toLowerCase().indexOf('account paid')!==-1)
                accountClosed=true;
              })
            } 
            return  <>
            <ListItemButton onClick={()=>this.handleCollapse({type:'trade_'+index,currentlyOpen:open})}>
              <ListItemText 
              primary={trade.CreditorId ? trade.CreditorId[0].Name[0]._text[0]:''}
              secondary={<Grid container style={{maxWidth:400}} direction='column'>
                <div>Reported: {trade.DateReported?moment(trade.DateReported[0]._text[0]).format('MMM YYYY'):''}</div>
                {trade.PreviousHighPaymentRates && <MDTypography fontSize={14} fontWeight='light' color='error'>{trade.PreviousHighPaymentRates[0].PreviousHighPaymentRate[0]._attributes.description} reported {moment(trade.PreviousHighPaymentRates[0].PreviousHighPaymentRate[0].Date[0]._text[0]).format('MMM YYYY')}</MDTypography>}
              </Grid>}
              />
              <Grid style={{maxWidth:130}} justifyContent='center' alignItems={'end'} container direction={'column'}>{trade.BalanceAmount ? this.removeLeadingZeros(trade.BalanceAmount[0]._text[0],true):'-'}
              {accountClosed===null ? <></>:  accountClosed ? <MDTypography fontWeight='light' color='error'>CLOSED</MDTypography> : <MDTypography fontWeight='light' color="success">OPEN</MDTypography>}
              </Grid>
              {open ? <ExpandLess /> : <ExpandMore />}
             
                  
                </ListItemButton>
                
                <Collapse in={open} timeout="auto" unmountOnExit style={{margin:'8px 16px'}}>
                <Grid container style={{fontSize:16,color:'#4d4b4a'}}>
                <Grid item xs={12}>
                <h4 style={{borderBottom:'2px solid rgb(85, 128, 173)',marginBottom:8}}>OVERVIEW</h4>

                <Grid container justifyContent={'space-between'} ><span>Balance</span>{trade.BalanceAmount ? this.removeLeadingZeros(trade.BalanceAmount[0]._text[0],true):''}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Hight Credit</span>{trade.HighCreditAmount ? this.removeLeadingZeros(trade.HighCreditAmount[0]._text[0],true):''}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Past Due</span>{trade.PastDueAmount?this.removeLeadingZeros(trade.PastDueAmount[0]._text[0],true):'0'}</Grid>
                <h4 style={{borderBottom:'2px solid rgb(85, 128, 173)',marginTop:16,marginBottom:8}}>ACCOUNT DETAILS</h4>
                <Grid container justifyContent={'space-between'} ><span>Payment Details</span>{trade.PaymentRate ? trade.PaymentRate[0]._attributes.description:''}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Missed Payments</span>{trade.PreviousHighPaymentRates ? trade.PreviousHighPaymentRates[0].PreviousHighPaymentRate[0]._attributes.description:'0'}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Type</span>{trade.PortfolioType ? trade.PortfolioType[0]._attributes.description:''}</Grid>
                {/* <Grid container justifyContent={'space-between'} ><span>Term Amount</span>{trade.PaymentTermAmount ? this.removeLeadingZeros(trade.PaymentTermAmount[0]._text[0]):''}</Grid> */}
                <Grid container justifyContent={'space-between'} ><span>Last Activity</span>{trade.DateLastActivityOrPayment?moment(trade.DateLastActivityOrPayment[0]._text[0]).format('MMM YYYY'):'-'}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Open Date</span>{trade.DateOpened?moment(trade.DateOpened[0]._text[0]).format('MMM YYYY'):''}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Last Reported</span>{trade.DateReported?moment(trade.DateReported[0]._text[0]).format('MMM YYYY'):''}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Narrative</span>{trade.Narratives ? <div>{trade.Narratives[0].Narrative.map(narrative=><span>{narrative._attributes.description}.</span>)}</div>:trade.Status?trade.Status[0]._attributes.description:'-'}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Association</span>{trade?.Association ?  trade?.Association[0]?._attributes.description:'-'}</Grid>
                </Grid>
              </Grid>
             </Collapse>
                <Divider />
                </>
          })}
          </List>
            </Card>
          </Grid>
          </Grid>
          }
          {this.state?.equifaxInfo?.CNCollections && <Grid style={{margin:8,padding:8}}  container justifyContent={'center'}><Grid item xs={12} md={6} style={{marginTop:16}}>
          <Card style={{padding:16,}}>
            <h3>Collections</h3>
           
          <List style={{marginTop:16}}>
          {this.state?.equifaxInfo?.CNCollections[0]?.CNCollection.map((collection,index)=>{
            let open = this.state.openedCollapibles.indexOf('collections_'+index)!==-1;
            return  <>
            <ListItemButton onClick={()=>this.handleCollapse({type:'collections_'+index,currentlyOpen:open})}>
              <ListItemText 
              primary={collection.CollectionCreditor ? collection.CollectionCreditor[0].AccountNumberAndOrName[0]._text:'Collector name unavailable'}
              secondary={`Assigned: ${collection.AssignedDate?moment(collection.AssignedDate[0]._text[0]).format('MMM YYYY'):''}`}
              />
              {collection.BalanceAmount ? this.removeLeadingZeros(collection.BalanceAmount[0]._text[0],true):''}
              {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit style={{margin:'8px 16px'}}>
                <Grid container style={{fontSize:16,color:'#4d4b4a'}}>
                <Grid item xs={12}>
                <h4 style={{borderBottom:'2px solid rgb(85, 128, 173)',marginBottom:8}}>OVERVIEW</h4>
                <Grid container justifyContent={'space-between'} ><span>Balance</span>{collection.BalanceAmount ? this.removeLeadingZeros(collection.BalanceAmount[0]._text[0],true):''}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Original Amount</span>{collection.OriginalAmount ? this.removeLeadingZeros(collection.OriginalAmount[0]._text[0],true):''}</Grid>
                <h4 style={{borderBottom:'2px solid rgb(85, 128, 173)',marginTop:16,marginBottom:8}}>COLLECTION DETAILS</h4>
                <Grid container justifyContent={'space-between'} ><span>Remakrs</span>{collection._attributes ? collection._attributes.description:''}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Last Payment on Account</span>{collection.DateOfLastPayment?moment(collection.DateOfLastPayment[0]._text[0]).format('MMM YYYY'):'-'}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Assigned Date</span>{collection.AssignedDate?moment(collection.AssignedDate[0]._text[0]).format('MMM YYYY'):'-'}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Date Paid</span>{collection.DatePaid?moment(collection.DatePaid[0]._text[0]).format('MMM YYYY'):'-'}</Grid>
                </Grid>
              </Grid>
             </Collapse>
                <Divider />
                </>
          })}
          </List>
            </Card>
          </Grid>
          </Grid>
          }


          {this.state?.equifaxInfo?.CNBankruptciesOrActs && <Grid style={{margin:8,padding:8}}  container justifyContent={'center'}><Grid item xs={12} md={6} style={{marginTop:16}}>
          <Card style={{padding:16,}}>
            <h3>Bankruptcies Or Acts</h3>
           
          <List style={{marginTop:16}}>
          {this.state?.equifaxInfo?.CNBankruptciesOrActs[0]?.CNBankruptcyOrAct.map((bankruptcy,index)=>{
            let open = this.state.openedCollapibles.indexOf('bankruptcy_'+index)!==-1;
            return  <>
            <ListItemButton onClick={()=>this.handleCollapse({type:'bankruptcy_'+index,currentlyOpen:open})}>
              <ListItemText 
              primary={bankruptcy.CaseNumberAndTrustee?bankruptcy.CaseNumberAndTrustee[0]._text[0]:'Not Disclosed'}
              secondary={`Date Filed: ${bankruptcy.DateFiled?moment(bankruptcy.DateFiled[0]._text[0]).format('MMM YYYY'):''}`}
              />
              {bankruptcy.AssetAmount ? this.removeLeadingZeros(bankruptcy.AssetAmount[0]._text[0],true):''}
              {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit style={{margin:'8px 16px'}}>
                <Grid container style={{fontSize:16,color:'#4d4b4a'}}>
                <Grid item xs={12}>
                <h4 style={{borderBottom:'2px solid rgb(85, 128, 173)',marginBottom:8}}>DETAILS</h4>
                <Grid container justifyContent={'space-between'} ><span>Asset Amount</span>{bankruptcy.AssetAmount ? this.removeLeadingZeros(bankruptcy.AssetAmount[0]._text[0],true):'-'}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Liability Amount</span>{bankruptcy.LiabilityAmount ? this.removeLeadingZeros(bankruptcy.LiabilityAmount[0]._text[0],true):'-'}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Intent or Disposition</span>{bankruptcy.IntentOrDisposition[0]._attributes.description}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Type</span>{bankruptcy.Type[0]._attributes.description}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Date Filed</span>{bankruptcy.DateFiled?moment(bankruptcy.DateFiled[0]._text[0]).format('MMM YYYY'):'-'}</Grid>
                </Grid>
              </Grid>
             </Collapse>
                <Divider />
                </>
          })}
          </List>
            </Card>
          </Grid>
          </Grid>
          }



          {this.state?.equifaxInfo?.CNBankAccounts && <Grid style={{margin:8,padding:8}}  container justifyContent={'center'}><Grid item xs={12} md={6} style={{marginTop:16}}>
          <Card style={{padding:16,}}>
            <h3>Bank Accounts</h3>
           
          <List style={{marginTop:16}}>
          {this.state?.equifaxInfo?.CNBankAccounts[0]?.CNBankAccount.map((bankAccount,index)=>{
            let open = this.state.openedCollapibles.indexOf('bankAccount_'+index)!==-1;
            return  <>
            <ListItemButton onClick={()=>this.handleCollapse({type:'bankAccount_'+index,currentlyOpen:open})}>
              <ListItemText 
              primary={bankAccount.CreditorId?bankAccount.CreditorId[0].Name[0]._text[0]:'Not Disclosed'}
              secondary={`Reported: ${bankAccount.DateReported?moment(bankAccount.DateReported[0]._text[0]).format('MMM YYYY'):''}`}
              />
              {bankAccount.BalanceAmount ? this.removeLeadingZeros(bankAccount.BalanceAmount[0]._text[0],true):''}
              {open ? <ExpandLess /> : <ExpandMore />}
                </ListItemButton>
                <Collapse in={open} timeout="auto" unmountOnExit style={{margin:'8px 16px'}}>
                <Grid container style={{fontSize:16,color:'#4d4b4a'}}>
                <Grid item xs={12}>
                <h4 style={{borderBottom:'2px solid rgb(85, 128, 173)',marginBottom:8}}>DETAILS</h4>
                <Grid container justifyContent={'space-between'} ><span>Figure Amount</span>{bankAccount.FigureAmount ? this.removeLeadingZeros(bankAccount.FigureAmount[0]._text[0]):'-'}</Grid>
                <Grid container justifyContent={'space-between'} ><span>NSFs</span>{bankAccount.NumberOfNSF ? this.removeLeadingZeros(bankAccount.NumberOfNSF[0]._text[0]):'-'}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Type</span>{bankAccount._attributes.description}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Reported</span>{bankAccount.DateReported?moment(bankAccount.DateReported[0]._text[0]).format('MMM YYYY'):''}</Grid>
                <Grid container justifyContent={'space-between'} ><span>Date Opened</span>{bankAccount.DateOpened?moment(bankAccount.DateOpened[0]._text[0]).format('MMM YYYY'):''}</Grid>
                </Grid>
              </Grid>
             </Collapse>
                <Divider />
                </>
          })}
          </List>
            </Card>
          </Grid>
          </Grid>
          }
          {this.state?.equifaxInfo?.CNEmployments && <Grid style={{margin:8,padding:8}}  container justifyContent={'center'}><Grid item xs={12} md={6} style={{marginTop:16}}>
          <Card style={{padding:16,}}>
            <h3>Employments</h3>
           
          <List style={{marginTop:16}}>
          {this.state?.equifaxInfo?.CNEmployments[0]?.CNEmployment.map((employment,index)=>{
            let open = this.state.openedCollapibles.indexOf('employment_'+index)!==-1;
            return  <>
            <ListItem onClick={()=>this.handleCollapse({type:'employment_'+index,currentlyOpen:open})}>
              <ListItemText 
              primary={employment.Employer ? employment.Employer[0]._text[0]:employment.Occupation ? employment.Occupation[0]._text[0]:"Not Disclosed"}
              secondary={employment._attributes.description}
              />
                </ListItem>
                <Divider />
                </>
          })}
          </List>
            </Card>
          </Grid>
          </Grid>
          }
         
          </div>
          }
        </Dialog>
    </>
  );
  
}
}
CreditInfo.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleLeaseChange: PropTypes.func.isRequired,
  handleApproval: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired,
  // errorFields: PropTypes.arrayOf(PropTypes.string),
};
export default CreditInfo;
