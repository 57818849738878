import { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import axios from "../../../axios";
import { getAuthHeaders } from "helpers";
import { useNavigate } from "react-router-dom";
import moment from "moment";

function Analytics() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [lastUpdated, setLastUpdated] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get("/v2/application/count", {
          headers: getAuthHeaders(),
        });
        console.log("res.data", res.data);
        setData(res.data);
        setLastUpdated(moment());
        setLoading(false);
      } catch (error) {
        console.log("error", error);
        setLoading(false);
      }
    };

    getData();
  }, []);
  // const { sales, tasks } = reportsLineChartData;

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={1}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  onClick={() => {
                    navigate("/pending");
                  }}
                  color="info"
                  icon="content_paste"
                  title="Pending"
                  count={data?.pending}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: lastUpdated
                      ? "Last updated : " + lastUpdated?.format("hh:mm A")
                      : "",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  onClick={() => {
                    navigate("/approved");
                  }}
                  color="success"
                  icon="check"
                  title="Approved"
                  count={data?.approved}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: lastUpdated
                      ? "Last updated : " + lastUpdated?.format("hh:mm A")
                      : "",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  onClick={() => {
                    navigate("/rejected");
                  }}
                  color="error"
                  icon="clear"
                  title="Rejected"
                  count={data?.rejected}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: lastUpdated
                      ? "Last updated : " + lastUpdated?.format("hh:mm A")
                      : "",
                  }}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <MDBox mb={1.5}>
                <ComplexStatisticsCard
                  onClick={() => {
                    navigate("/unsubmitted");
                  }}
                  color="warning"
                  icon="question_mark"
                  title="Unsubmitted"
                  count={data?.unsubmitted}
                  percentage={{
                    color: "success",
                    amount: "",
                    label: lastUpdated
                      ? "Last updated : " + lastUpdated?.format("hh:mm A")
                      : "",
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        {/* <MDBox mt={10}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox> */}
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Analytics;
