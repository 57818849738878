/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Billing page components
import Transaction from "components/Transaction";
import MDButton from "components/MDButton";
import { Refresh } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";

function getColorForStatus(status) {
  switch (status) {
    case "verified":
      return "success";
    case "rejected":
      return "error";
    case "reviewing":
      return "warning";
    default:
      return "dark";
  }
}
function getIconForStatus(status) {
  switch (status) {
    case "verified":
      return "check";
    case "rejected":
      return "clear";
    case "reviewing":
      return "receipt";
    default:
      return "priority_high";
  }
}

function getStatusForItem(item) {
  if (item.verified === 1) return "Verified";
  if (item.verified === 0) return "Rejected";
  if (item.fileName !== null) return "Reviewing";
  if (item.fileName === null) return "Pending";

  return "";
}

function Transactions({
  handleChange,
  data,
  handleFileClick,
  handleDocumentUpdate,
  removeFile,
  savingID,
  onRefresh,
  refreshing
}) {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        pt={3}
        px={2}
      >
        <MDBox display="flex" justifyContent="start" alignItems="center">
          <Icon color="inherit" fontSize="small">
            account_circle
          </Icon>
          <MDTypography
            px={1}
            variant="h5"
            fontWeight="medium"
            textTransform="capitalize"
          >
            {data.firstName} {data.lastName}
          </MDTypography>
        </MDBox>
        <MDBox >
        {refreshing ? <CircularProgress/> : <MDButton onClick={onRefresh} color='info' variant='contained' startIcon={<Refresh/>}>REFRESH</MDButton>}

        </MDBox>
      </MDBox>
      <MDBox>
        <MDBox>
          <MDTypography variant="body2" color="dark" px={2}>
            In order to continue, please provide the required documents.
          </MDTypography>
        </MDBox>
      </MDBox>

      <MDBox pt={3} pb={2} px={2}>
        <MDBox
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          {data &&
            data.documents &&
            data.documents.map((doc) => (
              <Transaction
                fileName={doc.fileName}
                ID={doc.ID}
                shortName={doc.type}
                saving={savingID === doc.ID}
                color={getColorForStatus(getStatusForItem(doc).toLowerCase())}
                icon={getIconForStatus(getStatusForItem(doc).toLowerCase())}
                name={doc.name}
                value={getStatusForItem(doc)}
                handleChange={handleChange}
                handleFileClick={() => handleFileClick(doc.fileName)}
                handleDocumentUpdate={(status, comment) =>
                  handleDocumentUpdate(status, doc.ID, comment)
                }
                removeFile={() => removeFile(doc.ID)}
                comment={doc.comment}
                verified={doc.verified}
                verifiedByName={doc.verifiedByName}
              />
            ))}
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Transactions;

Transactions.defaultProps = {
  handleFileClick: null,
  handleDocumentUpdate: null,
  removeFile: null,
};

// Typechecking props of the Transaction
Transactions.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleFileClick: PropTypes.func,
  handleDocumentUpdate: PropTypes.func,
  onRefresh: PropTypes.func.isRequired,
  removeFile: PropTypes.func,
  refreshing: PropTypes.bool.isRequired,
  savingID: PropTypes.string.isRequired,
  data: PropTypes.isRequired,
  
};
