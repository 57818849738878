/* eslint-disable react/prefer-stateless-function */

/* eslint-disable */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import {
  Grid,
  List,
  ListItem,
  RadioGroup,
  Radio,
  Card,
  CircularProgress,
  Select,
  MenuItem,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
  InputAdornment,
  ListItemText,
  ListItemAvatar,
  Avatar,
  InputLabel,
  Dialog,
  DialogContent,
} from "@mui/material";
import { encrypt } from "../../../constants";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import React from "react";
import QRCode from "react-qr-code";
import { getTerms } from "constants";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import {
  AccessTime,
  Check,
  Clear,
  Email,
  Person,
  PhoneAndroid,
  QrCode,
  Search,
} from "@mui/icons-material";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import { getAuthHeaders } from "helpers";

import axios from "../../../axios";
import withParams from "hoc";
import moment from "moment";
import InputMask from "react-input-mask";

const PhoneInput = (props) => (
  <InputMask
    mask="+1 (999) 999-9999"
    defaultValue={props.defaultValue}
    onChange={props.onChange}
    onBlur={props.onBlur}
  >
    {(inputProps) => (
      <TextField {...inputProps} {...props} type="tel" disableUnderline />
    )}
  </InputMask>
);
class Advisor extends React.Component {
  state = {
    stores: [],
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    financeAmount: null,
    vehicle: null,
    vin: null,
    selectedStoreSerial: null,
    qr: null,
    number: null,
    inputType: null,
    overriddenTotal: null,
    loading: true,
    lookingUp: false,
    showAlert: false,
    leaseTerm: 6,
    interestRate: 9.99,
    monthlyPayment: 0,
  };

  constructor(props) {
    super(props);
    let selectedStoreSerial = localStorage.getItem("selectedStoreSerial");
    if (selectedStoreSerial)
      this.state.selectedStoreSerial = selectedStoreSerial;
    let terms = getTerms();
    this.state.leaseTerm = terms[0].value;
    this.state.interestRate = terms[0].interestRate;
  }

  reset = () => {
    this.setState({
      firstName: null,
      lastName: null,
      phone: null,
      email: null,
      financeAmount: null,
      vehicle: null,
      vin: null,
      number: null,
      inputType: null,
      loading: false,
      overriddenTotal: null,
      total: null,
      lookingUp: false,
    });
  };

  handleChange = (e) => {
    this.setState(
      { [e.target.name]: e.target.value },
      e.target.name == "total"
        ? () => {
            let monthlyPayment = this.calculatePaymentAmount(
              this.state.leaseTerm,

              this.state.interestRate,
              e.target.value
            );
            this.setState({ monthlyPayment });
          }
        : null
    );
  };
  handleStoreSelect = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {
      localStorage.setItem("selectedStoreSerial", e.target.value);
    });
  };

  getStores = async () => {
    this.setState({ loading: true });
    try {
      const result = await axios.get(`/v2/store/`, getAuthHeaders());
      console.log("stores result");
      console.log(result);
      if (result.data.length == 0) throw "No Stores Found ";
      this.setState({
        stores: result.data,
      });
      return this.setState({
        loading: false,
      });
    } catch (e) {
      alert(e);
      this.setState({ loading: false });
    }
  };

  getAvisorTotal = async ({ quoteNumber, quoteType }) => {
    this.setState({ loadingAdvisorTotal: true });
    try {
      const result = await axios.get(
        `/v2/application/advisor_link?quote=${quoteNumber}&quote_type=${quoteType}&store=${this.state.selectedStoreSerial}`,
        getAuthHeaders()
      );
      console.log("advisor link result");
      console.log(result);
      return this.setState(
        {
          overriddenTotal:
            result.data.length == 0 ? null : result.data[0].total,
          total: result.data.length == 0 ? null : result.data[0].total,
          loadingAdvisorTotal: false,
        },
        () => {
          let terms = getTerms();

          let monthlyPayment = this.calculatePaymentAmount(
            terms[0].value,
            terms[0].interestRate
          );
          this.setState({ monthlyPayment });
        }
      );
    } catch (e) {
      alert(e);
      this.setState({ loadingAdvisorTotal: false });
    }
  };

  createAdvisorTotalLink = async () => {
    this.setState({ creatingAdvisorTotal: true });
    var total =
      this.state.total ??
      this.state.overriddenTotal ??
      this.state.financeAmount;
    try {
      const {
        firstName,
        lastName,
        phone,
        email,
        street,
        unit,
        city,
        province,
        postalCode,
        address,
        country,
        vehicle,
        vin,
        leaseTerm,
        interestRate,
      } = this.state;
      let link = this.generateLink();
      let body = {
        total,
        link,
        quote: this.state.number,
        quote_type: this.state.inputType == "quote_number" ? "ro" : "parts",
        store: this.state.selectedStoreSerial,
        firstName,
        lastName,
        phone,
        email: email.trim(),
        street,
        unit,
        city,
        province,
        postalCode,
        address: address.trim(),
        country,
        vehicle,
        vin,
        leaseTerm,
        interestRate,
      };
      console.log(body);
      const result = await axios.post(
        `/v2/application/advisor_link`,
        body,
        getAuthHeaders()
      );
      console.log("creatingAdvisorTotal result");
      console.log(result);
      return this.setState({
        creatingAdvisorTotal: false,
        overriddenTotal: total,
      });
    } catch (e) {
      alert(e);
      this.setState({ creatingAdvisorTotal: false });
    }
  };

  sendMessage = async ({ type = "email" }) => {
    this.setState({ sending: true });
    let to =
      type == "email" ? this.state.email.trim() : this.state.phone.trim();
    let link = this.generateLink();
    try {
      const result = await axios.post(
        `/v2/application/link`,
        { name: this.state.firstName, type, to, link },
        getAuthHeaders()
      );
      console.log("post link result");
      console.log(result);
      this.setState(
        {
          sending: false,
          showAlert: true,
        },
        () => {
          setTimeout(() => {
            this.setState({ showAlert: false });
          }, 1500);
        }
      );
    } catch (e) {
      alert(e);
      this.setState({ sending: false });
    }
  };

  lookupRO = async ({ quoteNumber, serial }) => {
    if (!quoteNumber || quoteNumber.length < 3) return alert("Invalid input");
    this.setState({ lookingUp: true });
    try {
      if (quoteNumber) {
        const result = await axios.get(
          `/v2/pbs/ro/${quoteNumber}?serial=${serial}`,
          getAuthHeaders()
        );
        console.log("ro result");
        console.log(result);
        if (result.data.Items.length == 0) throw "No Result Found FROM PBS";
        let info = result.data.Items[0];
        var pendingRequestsTotal = 0.0;

        info?.RepairOrderPendingRequests?.forEach((pendingRequest) => {
          pendingRequest?.LabourLines?.forEach((labourLine) => {
            pendingRequestsTotal += parseFloat(labourLine.Price);
          });
          pendingRequest?.PartLines?.forEach((partLine) => {
            pendingRequestsTotal += parseFloat(partLine.ExtendedPrice);
          });
        });
        info?.RepairOrderDeferredRequests?.forEach((deferredRequest) => {
          deferredRequest?.LabourLines?.forEach((labourLine) => {
            pendingRequestsTotal += parseFloat(labourLine.Price);
          });
          deferredRequest?.PartLines?.forEach((partLine) => {
            pendingRequestsTotal += parseFloat(partLine.ExtendedPrice);
          });
        });
        console.log("pending requests total : ", pendingRequestsTotal);

        console.log(
          "without adding tax in pending: ",
          info.RepairOrderCustomerSummary.InvoiceTotal + pendingRequestsTotal
        );
        pendingRequestsTotal =
          pendingRequestsTotal * 0.05 + pendingRequestsTotal;

        console.log(
          "with adding tax in pending: ",
          info.RepairOrderCustomerSummary.InvoiceTotal + pendingRequestsTotal
        );

        this.setState(
          {
            firstName: info.ContactFirstName,
            lastName: info.ContactLastName,
            phone: info.ContactCellPhone,
            email: info.ContactEmailAddress,
            street: info.ContactAddress,
            unit: info.ContactApartmentNumber,
            city: info.ContactCity,
            province: info.ContactState,
            postalCode: info.ContactZipCode,
            address: `${info.ContactApartmentNumber} ${info.ContactAddress} ${info.ContactCity}`,
            country:
              info.ContactCounty == null || info.ContactCounty == ""
                ? "CA"
                : info.ContactCounty,
            financeAmount: parseFloat(
              info.RepairOrderCustomerSummary.InvoiceTotal +
                pendingRequestsTotal
            ).toFixed(2),
            vehicle:
              info.VehicleMake +
              ", " +
              info.VehicleModel +
              ", " +
              info.VehicleYear,
            vin: info.VehicleVIN,
          },
          () => {
            this.getAvisorTotal({ quoteNumber, quoteType: "ro" });
          }
        );
      }

      return this.setState({
        lookingUp: false,
      });
    } catch (e) {
      alert(e);
      this.setState({ lookingUp: false });
    }
  };

  handleLeaseChange = (e) => {
    let terms = getTerms();
    var selectedTerm = terms.filter((term) => term.value == e.target.value);
    if (selectedTerm.length > 0)
      this.setState({
        leaseTerm: e.target.value,
        interestRate: selectedTerm[0].interestRate,
        overriddenTotal: null,
        monthlyPayment: this.calculatePaymentAmount(
          e.target.value,
          selectedTerm[0].interestRate
        ),
      });
  };

  calculatePaymentAmount = (loanTermInMonths, annualRate, total) => {
    let PV =
      this.state.total ??
      this.state.overriddenTotal ??
      this.state.financeAmount;
    console.log("PV");
    console.log(PV);
    console.log(annualRate);
    console.log(loanTermInMonths);
    let r = annualRate / 12 / 100;

    let P = (r * PV) / (1 - Math.pow(1 + r, -loanTermInMonths));
    return P.toFixed(2);
  };

  lookupPartsQuote = async ({ partsQuote, serial }) => {
    if (!partsQuote || partsQuote.length < 3) return alert("Invalid input");
    this.setState({ lookingUp: true });
    try {
      const result = await axios.get(
        `/v2/pbs/parts_quote/?serial=${serial}&parts_quote_number=${partsQuote}`,
        getAuthHeaders()
      );
      console.log("parts quote result");
      console.log(result);
      if (result.data?.PartsQuotes?.length == 0)
        throw "No Result Found FROM PBS";
      let info = result.data.PartsQuotes[0];
      let contactInfo = result.data.contactInfo[0]?.Contacts[0];
      let terms = getTerms();

      this.setState(
        {
          firstName: contactInfo.FirstName,
          lastName: contactInfo.LastName,
          phone: contactInfo.CellPhone,
          email: contactInfo.EmailAddress,
          street: contactInfo.Address,
          unit: contactInfo.ApartmentNumber,
          city: contactInfo.City,
          province: contactInfo.State,
          postalCode: contactInfo.ZipCode,
          address: `${contactInfo.ApartmentNumber} ${contactInfo.Address} ${contactInfo.City}`,
          country:
            contactInfo.County == null || contactInfo.County == ""
              ? "CA"
              : contactInfo.County,
          financeAmount: info.Summary.TotalQuote,
          vehicle:
            info.VehicleMake +
            ", " +
            info.VehicleModel +
            ", " +
            info.VehicleYear,
          vin: info.VehicleVIN,
        },
        () =>
          this.getAvisorTotal({ quoteNumber: partsQuote, quoteType: "parts" })
      );

      return this.setState({
        lookingUp: false,
      });
    } catch (e) {
      alert(e);
      this.setState({ lookingUp: false });
    }
  };

  generateQR = () => {
    this.setState({ qr: this.generateLink() });
  };

  generateLink = () => {
    let serial = encrypt(this.state.selectedStoreSerial.toString());
    let type = this.state.inputType;
    let number = encrypt(this.state.number.toString());
    let link = encodeURI(
      `https://finance.camclarkgroup.com/?${type}=${number}&serial=${serial}`
    );
    console.log(link);
    return link;
  };

  componentDidMount() {
    this.getStores();
  }

  render() {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={2} mb={2}>
          {this.state.loading ? (
            <Grid
              container
              style={{ height: "80vh" }}
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Grid>
          ) : (
            <MDBox mt={2} mb={2}>
              <Grid
                container
                spacing={4}
                style={{ minHeight: "80vh" }}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} md={4}>
                  <Card sx={{ p: 4 }}>
                    <FormControl
                      required
                      variant="standard"
                      sx={{ minWidth: 120 }}
                      fullWidth
                    >
                      <FormLabel style={{ fontSize: "1rem" }} id="store-label">
                        Store
                      </FormLabel>
                      <Select
                        onChange={this.handleStoreSelect}
                        labelId="store-label"
                        name="selectedStoreSerial"
                        label="Store"
                        defaultValue={this.state.selectedStoreSerial}
                      >
                        {this.state.stores.map((store) => {
                          return (
                            <MenuItem value={store.serial}>
                              {store.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ mt: 4 }} required>
                      <FormLabel
                        style={{ fontSize: "1rem" }}
                        id="inputTypeLabel"
                      >
                        Type
                      </FormLabel>
                      <RadioGroup
                        aria-labelledby="inputTypeLabel"
                        name="inputType"
                        value={this.state.inputType}
                        onChange={this.handleChange}
                      >
                        <FormControlLabel
                          value="quote_number"
                          label="Repair Order"
                          control={<Radio />}
                        />
                        <FormControlLabel
                          value="parts_quote"
                          label="Part's Quote"
                          control={<Radio />}
                        />
                      </RadioGroup>
                    </FormControl>
                    {this.state.selectedStoreSerial && this.state.inputType && (
                      <MDBox sx={{ mt: 2 }}>
                        <TextField
                          helperText={"Please enter numeric values only."}
                          type="number"
                          variant="standard"
                          fullWidth
                          disabled={this.state.lookingUp}
                          InputProps={{
                            // endAdornment: (
                            //   <MDButton onClick={()=>this.setState({number:null})} variant="text" color="info">
                            //     clear
                            //   </MDButton>
                            // ),
                            inputMode: "numeric",
                            pattern: "[0-9]",
                            inputProps: { min: 0 },
                          }}
                          sx={{ mb: 4 }}
                          required
                          label="Number"
                          name="number"
                          value={this.state.number}
                          onChange={(e) => {
                            this.handleChange(e);
                          }}
                        />
                        {this.state.lookingUp ? (
                          <MDBox
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <CircularProgress />
                          </MDBox>
                        ) : (
                          <MDBox display="flex" justifyContent="center">
                            {this.state.firstName == null && (
                              <MDButton
                                startIcon={<Search />}
                                onClick={() => {
                                  this.state.inputType == "quote_number"
                                    ? this.lookupRO({
                                        quoteNumber: this.state.number
                                          .toString()
                                          .trim(),
                                        serial: this.state.selectedStoreSerial,
                                      })
                                    : this.lookupPartsQuote({
                                        partsQuote: this.state.number
                                          .toString()
                                          .trim(),
                                        serial: this.state.selectedStoreSerial,
                                      });
                                }}
                                variant="gradient"
                                color="info"
                              >
                                SEARCH
                              </MDButton>
                            )}
                          </MDBox>
                        )}
                      </MDBox>
                    )}
                  </Card>
                </Grid>
                {this.state.firstName && (
                  <Grid item xs={12} md={6}>
                    <Card>
                      <MDBox display="flex" justifyContent="end">
                        {" "}
                        <MDButton
                          color="error"
                          variant="contained"
                          onClick={this.reset}
                          iconOnly
                        >
                          <Clear />
                        </MDButton>
                      </MDBox>
                      <MDBox sx={{ px: 4, pb: 4 }}>
                        <List>
                          <ListItem sx={{ mb: 2 }}>
                            <ListItemAvatar>
                              <Avatar>
                                <Person style={{ color: "white" }} />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              primary={`${this.state.firstName} ${this.state.lastName}`}
                              secondary={`${this.state.address}`}
                            />
                          </ListItem>
                        </List>
                        <PhoneInput
                          fullWidth
                          variant="standard"
                          sx={{ mb: 2 }}
                          label="Phone"
                          name="phone"
                          defaultValue={this.state.phone}
                          onBlur={this.handleChange}
                        />
                        <TextField
                          fullWidth
                          variant="standard"
                          sx={{ mb: 2 }}
                          label="Email"
                          name="email"
                          onBlur={this.handleChange}
                          defaultValue={this.state.email}
                        />
                        {!this.state.loadingAdvisorTotal && (
                          <TextField
                            fullWidth
                            variant="standard"
                            sx={{ mb: 2 }}
                            label="Finance Amount"
                            name="total"
                            onChange={(e) => {
                              if (this.state.overriddenTotal)
                                this.setState({ overriddenTotal: null });
                              this.handleChange(e);
                            }}
                            // onBlur={this.handleChange}
                            defaultValue={
                              this.state.overriddenTotal ??
                              this.state.financeAmount
                            }
                            helperText={
                              this.state.overriddenTotal
                                ? `Original PBS amount was $${this.state.financeAmount}`
                                : ""
                            }
                          />
                        )}
                        <FormControl
                          required
                          variant="standard"
                          sx={{ pt: 1, minWidth: 120 }}
                          fullWidth
                        >
                          <InputLabel id="leaseTerm-label">
                            Lease Term
                          </InputLabel>
                          <Select
                            fullWidth
                            onChange={this.handleLeaseChange}
                            labelId="leaseTerm-label"
                            name="leaseTerm"
                            label="Lease Term"
                            defaultValue={this.state.leaseTerm}
                          >
                            {getTerms().map((term) => (
                              <MenuItem
                                value={term.value}
                              >{`${term.name} at ${term.interestRate}%`}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <InputLabel
                          style={{ marginBottom: 32, marginTop: 16 }}
                          id="monthlyPayment-label"
                        >
                          <strong> Monthly Payment : </strong>

                          {parseFloat(
                            this.state.monthlyPayment
                          )?.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 2,
                          })}
                        </InputLabel>

                        {this.state.sending ||
                        this.state.loadingAdvisorTotal ? (
                          <CircularProgress />
                        ) : (
                          <MDBox
                            style={{ marginTop: 4 }}
                            display="flex"
                            justifyContent="space-between"
                          >
                            {!this.state.overriddenTotal ? (
                              this.state.creatingAdvisorTotal ? (
                                <CircularProgress />
                              ) : (
                                <>
                                  <MDButton
                                    onClick={this.createAdvisorTotalLink}
                                    color="primary"
                                  >
                                    Generate Application Link
                                  </MDButton>
                                </>
                              )
                            ) : (
                              <>
                                <MDButton
                                  disabled={this.state.sending}
                                  onClick={() =>
                                    this.sendMessage({ type: "sms" })
                                  }
                                  variant="contained"
                                  color="dark"
                                  startIcon={<PhoneAndroid />}
                                >
                                  SMS
                                </MDButton>
                                <MDButton
                                  disabled={this.state.sending}
                                  onClick={() =>
                                    this.sendMessage({ type: "email" })
                                  }
                                  variant="contained"
                                  color="dark"
                                  startIcon={<Email />}
                                >
                                  EMAIL
                                </MDButton>
                                <MDButton
                                  onClick={this.generateQR}
                                  variant="contained"
                                  color="dark"
                                  startIcon={<QrCode />}
                                >
                                  QR
                                </MDButton>
                              </>
                            )}
                          </MDBox>
                        )}
                      </MDBox>
                    </Card>
                  </Grid>
                )}
              </Grid>
            </MDBox>
          )}
        </MDBox>
        <Dialog
          fullScreen
          open={this.state.qr != null}
          onClose={() => this.setState({ qr: null })}
        >
          <DialogContent>
            <MDButton
              onClick={() => this.setState({ qr: null })}
              color="error"
              variant="gradient"
              startIcon={<Clear />}
            >
              CLOSE
            </MDButton>
            {this.state.qr && (
              <MDBox
                style={{ height: "100%" }}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <QRCode value={this.state.qr} />
              </MDBox>
            )}
          </DialogContent>
        </Dialog>
        <MDSnackbar
          open={this.state.showAlert}
          title={"Success"}
          icon={<Check />}
          content={"Message sent!"}
          dateTime={"Now"}
          color="success"
          close={() => {
            this.setState({ showAlert: false });
          }}
        />
        <Footer />
      </DashboardLayout>
    );
  }
}

export default withParams(Advisor);
