/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";

// @material-ui core components
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import InputMask from "react-input-mask";

const PhoneInput = (props) => (
  <InputMask
    mask="+1 (999) 999-9999"
    defaultValue={props.defaultValue}
    onChange={props.onChange}
    onBlur={props.onBlur}
  >
    {(inputProps) => (
      <FormField {...inputProps} {...props} type="tel" disableUnderline />
    )}
  </InputMask>
);
function EmploymentInfo({ handleChange, errorFields, data }) {
  return (
    <>
      <MDBox p={3}>
        <MDTypography variant="h5">Financial & Employment Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              defaultValue={data.employer}
              required
              error={errorFields.indexOf("employer") !== -1}
              name="employer"
              onBlur={handleChange}
              label="Employer"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneInput
              defaultValue={data.employerPhone}
              required
              error={errorFields.indexOf("employerPhone") !== -1}
              name="employerPhone"
              onChange={handleChange}
              label="Employer Phone"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              defaultValue={data.employmentPosition}
              required
              error={errorFields.indexOf("employmentPosition") !== -1}
              name="employmentPosition"
              onChange={handleChange}
              label="Position"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue={
                data.employmentDuration ? data.employmentDuration : ""
              }
              options={[
                "less than 6 months",
                "6 months +",
                "8 months +",
                "1 year +",
                "2 years +",
                "5 years +",
              ]}
              renderInput={(params) => (
                <FormField
                  required
                  {...params}
                  error={errorFields.indexOf("employmentDuration") !== -1}
                  name="employmentDuration"
                  onBlur={handleChange}
                  label="How long have you been working at current employer?"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue={data.employmentType ? data.employmentType : ""}
              options={[
                "Full-time Employment",
                "Part-time Employment",
                "Self-Employed",
                "Retired",
                "Student",
                "Disablity",
                "Seasonal Worker",
                "Unemployed with income",
                "Unemployed without income",
              ]}
              renderInput={(params) => (
                <FormField
                  {...params}
                  required
                  error={errorFields.indexOf("employmentType") !== -1}
                  name="employmentType"
                  onBlur={handleChange}
                  label="What is your employment type?"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue={data.annualIncome ? data.annualIncome : ""}
              options={[
                "$30,000+",
                "$40,000+",
                "$50,000+",
                "$60,000+",
                "$70,000+",
                "$80,000+",
                "$90,000+",
                "$100,000+",
                "$150,000+",
                "$200,000+",
              ]}
              renderInput={(params) => (
                <FormField
                  {...params}
                  required
                  error={errorFields.indexOf("annualIncome") !== -1}
                  name="annualIncome"
                  onBlur={handleChange}
                  label="What is your annual income?"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue={data.householdIncome ? data.householdIncome : ""}
              options={[
                "$30,000+",
                "$40,000+",
                "$50,000+",
                "$60,000+",
                "$70,000+",
                "$80,000+",
                "$90,000+",
                "$100,000+",
                "$150,000+",
                "$200,000+",
              ]}
              renderInput={(params) => (
                <FormField
                  {...params}
                  required
                  error={errorFields.indexOf("householdIncome") !== -1}
                  name="householdIncome"
                  onBlur={handleChange}
                  label="What is your household income?"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue={data.housingType ? data.housingType : ""}
              options={["Own", "Rent", "Mortgage", "Living with someone"]}
              renderInput={(params) => (
                <FormField
                  required
                  {...params}
                  error={errorFields.indexOf("housingType") !== -1}
                  name="housingType"
                  onBlur={handleChange}
                  label="What is your housing type?"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              defaultValue={data.rentAmount}
              required
              error={errorFields.indexOf("rentAmount") !== -1}
              name="rentAmount"
              onBlur={handleChange}
              label="Rent/ Mortgage Amount"
              placeholder=""
            />
          </Grid>
        </Grid>
      </MDBox>
    </>
  );
}
EmploymentInfo.defaultProps = {
  data: {},
};
EmploymentInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};
export default EmploymentInfo;
