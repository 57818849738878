// authConfig.js
export const msalConfig = {
  auth: {
    clientId: "844f7802-4968-4bac-aea1-b102ed3adf27",
    authority:
      "https://login.microsoftonline.com/53cce716-d0b1-4cef-b9ab-98a57050ba43",
    redirectUri: "https://finance.camclarkgroup.com",
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false, // Set to true if you have issues with session being lost after refresh in some browsers
  },
};
