/* eslint-disable react/prefer-stateless-function */

/* eslint-disable */

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
// import Footer from "layouts/pages/landing/components/Footer";
// import Faq from "layouts/pages/landing/components/Faq";
import MDBox from "components/MDBox";
import React from "react";
import withParams from "hoc";

class CustomerApplicationPage extends React.Component {
  // eslint-disable-line no-use-before-define

  render() {
    return (
      <PageLayout>
        <Container>
          <MDBox pb={3} mb={20}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%", mt: 8 }}
            >
              <Grid item xs={12} lg={8}>
                <MDBox p={3}>{this.props.children}</MDBox>
              </Grid>
            </Grid>
          </MDBox>
          {/* <Faq /> */}
        </Container>
        {/* <Footer /> */}
      </PageLayout>
    );
  }
}

export default withParams(CustomerApplicationPage);
