import axios from "axios";

const axiosInstance = axios.create({
  baseURL: "https://finance.camclarkgroup.com/api",
  timeout: 60000,
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lies within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // Check if the status code is 400, 401, or 403
    if ([401, 403].includes(error.response?.status)) {
      // Clear local and session storage
      localStorage.clear();
      sessionStorage.clear();

      // Refresh the page
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
