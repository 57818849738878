/* eslint-disable react/prefer-stateless-function */

/* eslint-disable */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { Grid, List, ListItem, Card, CircularProgress } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import React from "react";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import { AccessTime } from "@mui/icons-material";
import MDButton from "components/MDButton";
import { getAuthHeaders } from "helpers";

import axios from "../../../axios";
import withParams from "hoc";
import moment from "moment";

class Pending extends React.Component {
  state = {
    data: [],
    loading: true,
  };
  componentDidMount() {
    this.getData();
  }

  getData = async () => {
    try {
      const res = await axios.get(
        "/v2/application/pending?offset=0",
        getAuthHeaders()
      );
      console.log("res.data", res.data);
      this.setState({ data: res.data, loading: false });
    } catch (error) {
      console.log("error", error);
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={2} mb={2}>
          {!this.state.loading && (
            <Grid container>
              <MDTypography>
                {this.state.data.length} Pending Applications
              </MDTypography>
            </Grid>
          )}
          {this.state.loading && (
            <Grid
              container
              style={{ height: "80vh" }}
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Grid>
          )}

          <MDBox mt={5}>
            <Grid container spacing={5}>
              {this.state.data.map((application) => {
                let color = "success";
                if (application.creditScore < 599) color = "error";
                else if (application.creditScore <= 699) color = "warning";

                return (
                  <Grid item xs={12} md={6}>
                    <Card>
                      <MDBox
                        display="flex"
                        justifyContent="space-between"
                        px={2}
                        pt={2}
                      >
                        <MDBox display="flex">
                          <MDBox
                            width="4rem"
                            height="4rem"
                            bgColor={color}
                            variant="gradient"
                            coloredShadow={color}
                            borderRadius="xl"
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            color="white"
                            mt={-4}
                            mr={2}
                          >
                            {application.creditScore}
                          </MDBox>
                          <MDBox mt={-1}>
                            <MDTypography color={color} variant="h6">
                              {application.creditRemarks}
                            </MDTypography>
                            {/* <MDTypography component="div" variant="button" color="text">
                              50% credit utilization
                            </MDTypography> */}
                          </MDBox>
                        </MDBox>
                        <MDButton
                          onClick={() => {
                            this.props.navigate(
                              `/application/${application.ID}`
                            );
                          }}
                          color="secondary"
                          variant="gradient"
                        >
                          VIEW
                        </MDButton>
                      </MDBox>
                      <MDBox p={2} pb={0}>
                        <List>
                          <ListItem alignItems="center" divider>
                            <Grid container alignItems="center">
                              <Grid item sx={{ minWidth: { xs: 50, sm: 150 } }}>
                                <p
                                  style={{
                                    color: "grey",
                                    fontSize: "small",
                                    margin: 0,
                                  }}
                                >
                                  Name
                                </p>
                              </Grid>
                              <Grid
                                item
                                xs
                                sm={8}
                                sx={{ textAlign: { xs: "right", sm: "left" } }}
                              >
                                <MDTypography variant="h6">{`${application.firstName} ${application.lastName}`}</MDTypography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <ListItem alignItems="center" divider>
                            <Grid container alignItems="center">
                              <Grid item sx={{ minWidth: { xs: 50, sm: 150 } }}>
                                <p
                                  style={{
                                    color: "grey",
                                    fontSize: "small",
                                    margin: 0,
                                  }}
                                >
                                  Phone
                                </p>
                              </Grid>
                              <Grid
                                item
                                xs
                                sm={8}
                                sx={{ textAlign: { xs: "right", sm: "left" } }}
                              >
                                <MDTypography variant="h6">
                                  {application.phone}
                                </MDTypography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <ListItem alignItems="center" divider>
                            <Grid container alignItems="center">
                              <Grid item sx={{ minWidth: { xs: 50, sm: 150 } }}>
                                <p
                                  style={{
                                    color: "grey",
                                    fontSize: "small",
                                    margin: 0,
                                  }}
                                >
                                  Email
                                </p>
                              </Grid>
                              <Grid
                                item
                                xs
                                sm={8}
                                sx={{ textAlign: { xs: "right", sm: "left" } }}
                              >
                                <MDTypography variant="h6">
                                  {application.email}
                                </MDTypography>
                              </Grid>
                            </Grid>
                          </ListItem>
                          <ListItem alignItems="center" divider>
                            <Grid container alignItems="center">
                              <Grid item sx={{ minWidth: { xs: 50, sm: 150 } }}>
                                <p
                                  style={{
                                    color: "grey",
                                    fontSize: "small",
                                    margin: 0,
                                  }}
                                >
                                  Status
                                </p>
                              </Grid>
                              <Grid
                                item
                                xs
                                sm={8}
                                sx={{ textAlign: { xs: "right", sm: "left" } }}
                              >
                                <MDTypography variant="h6">
                                  Pending Approval
                                </MDTypography>
                              </Grid>
                            </Grid>
                          </ListItem>
                        </List>
                        <Grid
                          container
                          justifyContent="flex-end"
                          alignItems="center"
                          style={{ marginTop: 8, marginBottom: 6 }}
                        >
                          <AccessTime
                            sx={{ fontSize: "15px !important" }}
                            style={{ color: "grey" }}
                          />
                          <span
                            style={{
                              color: "grey",
                              fontSize: "small",
                              margin: 0,
                              marginLeft: 8,
                            }}
                          >
                            {moment(application.created).format("DD MMM YYYY")}
                          </span>
                        </Grid>
                      </MDBox>
                    </Card>
                  </Grid>
                );
              })}
            </Grid>
          </MDBox>
        </MDBox>
        <Footer />
      </DashboardLayout>
    );
  }
}

export default withParams(Pending);
