/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
import Analytics from "layouts/dashboards/analytics";
import Pending from "layouts/dashboards/pending";
import Unsubmitted from "layouts/dashboards/unsubmitted";
import Approved from "layouts/dashboards/approved";
import Rejected from "layouts/dashboards/rejected";
import Detail from "layouts/dashboards/detail";
import Advisor from "layouts/dashboards/advisor";
import Timeline from "layouts/dashboards/detail/components/Timeline";
// import Sales from "layouts/dashboards/sales";
// import Settings from "layouts/pages/account/settings";
import LandingPage from "layouts/pages/landing";
import CustomerApplicationStatus from "layouts/pages/customer-application";
import SignInBasic from "layouts/authentication/sign-in/basic";
import Logout from "layouts/authentication/logout";
// import SignInCover from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/profile.png";

const routes = [
  {
    type: "collapse",
    name: "User",
    key: "user",
    icon: <MDAvatar src={profilePicture} alt="userPic" size="sm" />,
    collapse: [
      // {
      //   name: "Settings",
      //   key: "profile-settings",
      //   route: "/pages/account/settings",
      //   component: <Settings />,
      // },
      {
        name: "Logout",
        key: "logout",
        route: "/authentication/logout",
        component: <Logout />,
      },
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    type: "collapse",
    noCollapse: true,

    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    route: "/dashboard",

    component: <Analytics />,
    allowed: ["admin"],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Pending",
    key: "pending",
    icon: <Icon fontSize="medium">content_paste</Icon>,
    route: "/pending",
    component: <Pending />,
    allowed: ["admin"],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Un-submitted",
    key: "unsubmitted",
    icon: <Icon fontSize="medium">question_mark</Icon>,
    route: "/unsubmitted",
    component: <Unsubmitted />,
    allowed: ["admin"],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Approved",
    key: "approved",
    icon: <Icon fontSize="medium">check</Icon>,
    route: "/approved",
    component: <Approved />,
    allowed: ["admin"],
  },
  {
    type: "collapse",
    noCollapse: true,
    name: "Rejected",
    key: "rejected",
    icon: <Icon fontSize="medium">clear</Icon>,
    route: "/rejected",
    component: <Rejected />,
    allowed: ["admin"],
  },
  {
    type: "route",
    noCollapse: true,
    hidden: true,
    name: "app-logs",
    key: "app-logs",
    icon: <Icon fontSize="medium">content_copy</Icon>,
    route: "/application/:id/logs",
    component: <Timeline />,
    allowed: ["admin"],
  },
  {
    type: "route",
    noCollapse: true,
    hidden: true,
    name: "app-details",
    key: "app-details",
    icon: <Icon fontSize="medium">content_copy</Icon>,
    route: "/application/:id",
    component: <Detail />,
    allowed: ["admin"],
  },

  {
    type: "route",
    noCollapse: true,
    hidden: true,
    name: "Basic",
    key: "basic",
    route: "/signin",
    component: <SignInBasic />,
  },

  {
    type: "collapse",
    noCollapse: true,
    name: "Advisor",
    icon: <Icon fontSize="medium">person</Icon>,
    key: "advisor-page",
    route: "/advisor",
    component: <Advisor />,
  },
  {
    type: "route",

    noCollapse: true,
    hidden: true,
    name: "Landing Page",
    key: "landing-page",
    route: "/",
    component: <LandingPage />,
  },
  {
    type: "route",
    noCollapse: true,
    hidden: true,
    name: "app-status",
    key: "app-status",
    icon: <Icon fontSize="medium">content_copy</Icon>,
    route: "/application/:id/status",
    component: <CustomerApplicationStatus />,
    allowed: ["admin"],
  },
];

export default routes;
