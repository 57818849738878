/* eslint-disable react/prefer-stateless-function */

/* eslint-disable */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Header from "layouts/pages/landing/components/Header";
// import Footer from "layouts/pages/landing/components/Footer";
// import Faq from "layouts/pages/landing/components/Faq";
import BasicInfo from "layouts/pages/landing/components/BasicInfo";
import EmploymentInfo from "layouts/pages/landing/components/EmploymentInfo";
import Identification from "layouts/pages/landing/components/Identification";
import FinanceAmount from "layouts/pages/landing/components/FinanceAmount";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React from "react";
import axios from "../../../axios";
import moment from "moment";
import { getAuthHeaders } from "../../../helpers";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormControl,
} from "@mui/material";
import { decrypt, getTerms } from "../../../constants";

class LandingPage extends React.Component {
  // eslint-disable-line no-use-before-define

  noHeader = false;

  financeRequiredFields = ["financeAmount"];
  basicRequiredFields = [
    "firstName",
    "lastName",
    "phone",
    "email",
    "dob",
    "address",
    "street",
    "city",
    "province",
    "postalCode",
    "country",
    "financeAmount",
  ];
  employmentRequiredFields = [
    "employer",
    "employerPhone",
    "employmentPosition",
    "employmentDuration",
    "employmentType",
    "annualIncome",
    "householdIncome",
    "housingType",
    "rentAmount",
  ];

  state = {
    saving: true,
    activeStep: 0,
    financeAmount: 0,
    leaseTerm: 6,
    interestRate: 9.99,
    quoteNumber: null, //quote number of either RO for pbs
    partsQuote: null, //parts quote for pbs
    serial: null, //serial number for pbs
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: null,
    sin: "",
    address: "",
    unit: "",
    street: "",
    city: "",
    province: "",
    postalCode: "",
    country: "Canada",
    employer: "",
    employerPhone: "",
    employmentPosition: "",
    employmentDuration: "",
    employmentType: "",
    annualIncome: "",
    householdIncome: "",
    housingType: "",
    rentAmount: "",
    check1: false,
    check2: false,
    overriddenTotal: null,
    errorFields: [],
  };

  minimumAmount = 500;

  constructor(props) {
    super(props);
    const queryParams = new URLSearchParams(window.location.search);
    this.noHeader = queryParams.get("no_header");

    let quoteNumber = queryParams.get("quote_number")
      ? decodeURI(queryParams.get("quote_number"))
      : null;
    let partsQuote = queryParams.get("parts_quote")
      ? decodeURI(queryParams.get("parts_quote"))
      : null;
    let serial = queryParams.get("serial")
      ? decodeURI(queryParams.get("serial"))
      : null;

    // console.log('quoteNumber')
    // console.log(quoteNumber)
    // console.log('partsQuote')
    // console.log(partsQuote)
    console.log("serial");
    console.log(serial);
    let errorDisplayed = false;

    if (quoteNumber) {
      this.state.quoteNumber = decrypt(quoteNumber);
      this.state.serial = decrypt(serial);

      console.log("this.state.serial");
      console.log(this.state.serial);
      console.log("this.state.quoteNumber");
      console.log(this.state.quoteNumber);
      if (!this.state.quoteNumber && quoteNumber) {
        alert("error parsing link");
        errorDisplayed = 1;
      } else {
        // this.lookupRO();
      }
    } else if (partsQuote) {
      this.state.partsQuote = decrypt(partsQuote);
      this.state.serial = decrypt(serial);
      if (!this.state.partsQuote && partsQuote) {
        alert("error parsing link");
        errorDisplayed = 1;
      } else {
        // this.lookupPartsQuote();
      }
    } else {
      this.state.saving = false;
    }
    let serial_manual = queryParams.get("serial_manual");

    this.state.serial = decrypt(serial);
    if (!this.state.serial && !errorDisplayed && serial) {
      alert("error parsing link");
    } else if (!serial && serial_manual) {
      this.state.serial = serial = serial_manual;
    }

    if (errorDisplayed) {
      this.state.saving = false;
    }

    this.getAvisorTotal({
      quoteNumber: this.state.quoteNumber ?? this.state.partsQuote,
      quoteType: this.state.quoteNumber ? "ro" : "parts",
      store: this.state.serial,
    });
  }

  getSteps = () => {
    return [
      "Finance Amount",
      "Personal",
      "Employment",
      "Consent",
      "Verification",
      "Submission",
    ];
  };

  getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <FinanceAmount
            data={{
              financeAmount: this.state.financeAmount,
              leaseTerm: this.state.leaseTerm,
              allowAmountChange:
                this.state.quoteNumber == null && this.state.partsQuote == null,
            }}
            errorFields={this.state.errorFields}
            handleChange={this.handleChange}
            loading={this.state.saving}
          />
        );
      case 1:
        return (
          <BasicInfo
            data={this.state}
            errorFields={this.state.errorFields}
            handleAddressChange={this.handleAddressChange}
            handleChange={this.handleChange}
            handleDateChange={this.handleDateChange}
          />
        );
      case 2:
        return (
          <EmploymentInfo
            errorFields={this.state.errorFields}
            handleChange={this.handleChange}
          />
        );
      case 3:
        return (
          <div>
            <p>
              The Pre-qualification Tool provides a free evaluation of the
              potential credit that you may qualify for from Cam Clark
              Automotive Group should you proceed to complete a credit
              application. If you agree to proceed with a pre-qualification, we
              will obtain your credit information by a “soft pull” (an inquiry
              not visible to other creditors viewing your credit report, that
              does not affect your credit score). Pre-qualification is for
              general information purposes only, and is not an approval or
              guarantee that we will extend credit to you or the amount of any
              credit that may be offered.
            </p>
            <Grid container>
              <Grid item xs>
                <FormControl>
                  <FormControlLabel
                    onChange={(e) =>
                      this.setState({ check1: e.target.checked })
                    }
                    sx={{ root: { width: "80% !important" } }}
                    value={this.state.check1}
                    labelPlacement="end"
                    control={<Checkbox />}
                    label="By checking this box, I give express consent to Cam Clark Automotive Group  or agent of Cam Clark Automotive Group for authentication of my identity and the collection/use of my credit information as described above in paragraph."
                  />
                </FormControl>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <FormControl>
                  <FormControlLabel
                    onChange={(e) =>
                      this.setState({ check2: e.target.checked })
                    }
                    value={this.state.check2}
                    labelPlacement="end"
                    control={<Checkbox />}
                    label="I agree that the credit information provided by Equifax is required to provide vehicle/parts/service finance options."
                  />
                </FormControl>
              </Grid>
            </Grid>
            <span style={{ fontSize: 12, color: "red" }}>
              *In order to continue, you must accept the terms by checking the
              boxes
            </span>
            {/* <Grid container>
              <Grid item xs>
                <FormControl>
                  <FormControlLabel
                    value={"end"}
                    labelPlacement="end"
                    control={<Checkbox />}
                    label="I am aware that this will NOT impact my credit score  as this is a soft credit pull."
                  />
                </FormControl>
              </Grid>
            </Grid> */}
          </div>
        );
      case 4:
        return (
          <Identification
            errorFields={this.state.errorFields}
            handleChange={this.handleChange}
          />
        );
      case 5:
        return <ResultPage {...this.state} />;
    }
  };

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      errorFields: this.state.errorFields.filter(
        (field) => field != e.target.name
      ),
    });

  handleDateChange = (date) =>
    this.setState({
      dob: moment(date).format("YYYY-MM-DD"),
      errorFields: this.state.errorFields.filter((field) => field != "dob"),
    });

  handleAddressChange = ({
    address,
    street,
    city,
    province,
    postalCode,
    country,
  }) => {
    if (!street || street.trim() == "") {
      return;
    }
    this.setState({ address, street, city, province, postalCode, country });
  };

  saveBasicInfo = async (e) => {
    e.preventDefault();
    console.log("this.state");
    console.log(this.state);
    this.setState({ saving: true });
    try {
      let {
        firstName,
        lastName,
        phone,
        email,
        dob,
        sin,
        unit,
        street,
        city,
        province,
        postalCode,
        country,
        leaseTerm,
        financeAmount,
        quoteNumber,
        partsQuote,
        serial,
      } = this.state;

      let terms = getTerms();
      var termIndex = terms.findIndex((term) => term.value == leaseTerm);
      let interestRate = terms[termIndex].interestRate;
      console.log("termIndex", termIndex);
      console.log("interestRate", interestRate);
      var errorFields = [];
      this.basicRequiredFields.forEach((field) => {
        if (field == "address" && this.state.manual && !this.state.address)
          return;
        if (
          this.state[field] == null ||
          this.state[field].toString().trim() == ""
        )
          errorFields.push(field);
      });

      if (errorFields.length > 0) {
        //in case there are error fields, highlight errors and scrool to first error
        console.log("errorFields");
        console.log(errorFields);
        this.setState({ errorFields, saving: false });
        if (errorFields[0] == "dob") alert("Date of Birth is required");
        const section = document.querySelector(`[name = "${errorFields[0]}"]`);
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        const body = {
          firstName,
          lastName,
          phone,
          email,
          dob,
          sin,
          unit,
          street,
          city,
          province,
          postalCode,
          country,
          financeAmount,
          leaseTerm,
          interestRate,
          serialNo: serial,
          roNo: quoteNumber,
          partsQuoteNo: partsQuote,
        };
        const result = await axios.post(
          "/v2/application/",
          body,
          getAuthHeaders()
        );
        console.log("result");
        console.log(result);
        return this.setState(
          {
            activeStep: this.state.activeStep + 1,
            applicationID: result.data.insertId,
            saving: false,
          },
          () => {
            const section = document.getElementById(`step-form`);
            section.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        );
      }
    } catch (e) {
      this.setState({ saving: false });

      alert(e);
    }
  };

  saveEmploymentInfo = async (e) => {
    e.preventDefault();
    this.setState({ saving: true });

    try {
      const {
        employer,
        employerPhone,
        employmentPosition,
        employmentDuration,
        employmentType,
        annualIncome,
        householdIncome,
        housingType,
        rentAmount,
      } = this.state;
      var errorFields = [];
      this.employmentRequiredFields.forEach((field) => {
        if (
          this.state[field] == null ||
          this.state[field].toString().trim() == ""
        )
          errorFields.push(field);
      });
      if (errorFields.length > 0) {
        //in case there are error fields, highlight errors and scrool to first error
        this.setState({ errorFields, saving: false });
        const section = document.querySelector(`[name = "${errorFields[0]}"]`);
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        const body = {
          applicationID: this.state.applicationID,
          employer,
          employerPhone,
          employmentDuration,
          employmentPosition,
          employmentType,
          annualIncome: parseFloat(annualIncome.substring(1).replace(",", "")),
          householdIncome: parseFloat(
            householdIncome.substring(1).replace(",", "")
          ),
          housingType,
          rentAmount,
        };
        const result = await axios.put(
          "/v2/application/employment",
          body,
          getAuthHeaders()
        );
        console.log("result");
        console.log(result);
        return this.setState(
          {
            activeStep: this.state.activeStep + 1,
            saving: false,
          },
          () => {
            const section = document.getElementById(`step-form`);
            section.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        );
      }
    } catch (e) {
      alert(e);
      this.setState({ saving: false });
    }
  };
  // lookupRO = async () => {
  //   this.setState({ saving: true });
  //   try {
  //     const { quoteNumber, serial } = this.state;
  //     var errorFields = [];
  //     // this.financeRequiredFields.forEach((field) => {
  //     //   if (
  //     //     this.state[field] == null ||
  //     //     this.state[field].toString().trim() == ""
  //     //   )
  //     //     errorFields.push(field);
  //     // });
  //     if (errorFields.length > 0) {
  //       //in case there are error fields, highlight errors and scrool to first error
  //       this.setState({ errorFields, saving: false });
  //       const section = document.querySelector(`[name = "${errorFields[0]}"]`);
  //       section.scrollIntoView({ behavior: "smooth", block: "center" });
  //     } else {
  //       //if quote number provided by URL
  //       if (quoteNumber) {
  //         const result = await axios.get(
  //           `/v2/pbs/ro/${quoteNumber}?serial=${serial}`,
  //           getAuthHeaders()
  //         );
  //         console.log("ro result");
  //         console.log(result);

  //         if (result.data.Items.length == 0) throw "No Result Found FROM PBS";
  //         let info = result.data.Items[0];
  //         var pendingRequestsTotal = 0.0;

  //         info?.RepairOrderPendingRequests?.forEach((pendingRequest) => {
  //           pendingRequest?.LabourLines?.forEach((labourLine) => {
  //             pendingRequestsTotal += parseFloat(labourLine.Price);
  //           });
  //           pendingRequest?.PartLines?.forEach((partLine) => {
  //             pendingRequestsTotal += parseFloat(partLine.ExtendedPrice);
  //           });
  //         });
  //         info?.RepairOrderDeferredRequests?.forEach((deferredRequest) => {
  //           deferredRequest?.LabourLines?.forEach((labourLine) => {
  //             pendingRequestsTotal += parseFloat(labourLine.Price);
  //           });
  //           deferredRequest?.PartLines?.forEach((partLine) => {
  //             pendingRequestsTotal += parseFloat(partLine.ExtendedPrice);
  //           });
  //         });
  //         console.log("pending requests total : ", pendingRequestsTotal);

  //         console.log(
  //           "without adding tax in pending: ",
  //           info.RepairOrderCustomerSummary.InvoiceTotal + pendingRequestsTotal
  //         );
  //         pendingRequestsTotal =
  //           pendingRequestsTotal * 0.05 + pendingRequestsTotal;

  //         console.log(
  //           "with adding tax in pending: ",
  //           info.RepairOrderCustomerSummary.InvoiceTotal + pendingRequestsTotal
  //         );

  //         this.setState({
  //           firstName: info.ContactFirstName,
  //           lastName: info.ContactLastName,
  //           phone: info.ContactCellPhone,
  //           email: info.ContactEmailAddress,
  //           street: info.ContactAddress,
  //           unit: info.ContactApartmentNumber,
  //           city: info.ContactCity,
  //           province: info.ContactState,
  //           postalCode: info.ContactZipCode,
  //           address: `${info.ContactApartmentNumber} ${info.ContactAddress} ${info.ContactCity}`,
  //           country:
  //             info.ContactCounty == null || info.ContactCounty == ""
  //               ? "CA"
  //               : info.ContactCounty,
  //           manual: 1,
  //           // financeAmount: parseFloat(
  //           //   info.RepairOrderCustomerSummary.InvoiceTotal +
  //           //     pendingRequestsTotal
  //           // ).toFixed(2),
  //         });
  //       } else {
  //         //user added finance amount manually
  //       }

  //       return this.setState({
  //         // activeStep: this.state.activeStep + 1,
  //         saving: false,
  //       });
  //     }
  //   } catch (e) {
  //     console.log("error looking up quote nymber");
  //     console.log(e);
  //     alert(e);
  //     this.setState({
  //       saving: false,
  //       quoteNumber: null, //quote number of either RO for pbs
  //       partsQuote: null, //parts quote for pbs
  //       serial: null, //serial number for pbs
  //     });
  //   }
  // };

  // lookupPartsQuote = async () => {
  //   this.setState({ saving: true });
  //   try {
  //     const { partsQuote, serial } = this.state;
  //     var errorFields = [];

  //     if (errorFields.length > 0) {
  //       //in case there are error fields, highlight errors and scrool to first error
  //       this.setState({ errorFields, saving: false });
  //       const section = document.querySelector(`[name = "${errorFields[0]}"]`);
  //       section.scrollIntoView({ behavior: "smooth", block: "center" });
  //     } else {
  //       //if quote number provided by URL
  //       if (partsQuote) {
  //         const result = await axios.get(
  //           `/v2/pbs/parts_quote/?serial=${serial}&parts_quote_number=${partsQuote}`,
  //           getAuthHeaders()
  //         );
  //         console.log("parts quote result");
  //         console.log(result);
  //         if (result.data?.PartsQuotes?.length == 0)
  //           throw "No Result Found FROM PBS";
  //         let info = result.data.PartsQuotes[0];
  //         let contactInfo = result.data.contactInfo[0]?.Contacts[0];
  //         this.setState({
  //           firstName: contactInfo.FirstName,
  //           lastName: contactInfo.LastName,
  //           phone: contactInfo.CellPhone,
  //           email: contactInfo.EmailAddress,
  //           street: contactInfo.Address,
  //           unit: contactInfo.ApartmentNumber,
  //           city: contactInfo.City,
  //           province: contactInfo.State,
  //           postalCode: contactInfo.ZipCode,
  //           address: `${contactInfo.ApartmentNumber} ${contactInfo.Address} ${contactInfo.City}`,
  //           country:
  //             contactInfo.County == null || contactInfo.County == ""
  //               ? "CA"
  //               : contactInfo.County,
  //           manual: 1,
  //           // financeAmount: info.Summary.TotalQuote,
  //         });
  //       } else {
  //         //user added finance amount manually
  //       }

  //       return this.setState({
  //         // activeStep: this.state.activeStep + 1,
  //         saving: false,
  //       });
  //     }
  //   } catch (e) {
  //     alert(e);
  //     this.setState({
  //       saving: false,
  //       quoteNumber: null, //quote number of either RO for pbs
  //       partsQuote: null, //parts quote for pbs
  //       serial: null, //serial number for pbs
  //     });
  //   }
  // };

  getAvisorTotal = async ({ quoteNumber, quoteType, store }) => {
    this.setState({ saving: true });
    try {
      console.log(
        `/v2/application/advisor_link?quote=${quoteNumber}&quote_type=${quoteType}&store=${store}`
      );
      const result = await axios.get(
        `/v2/application/advisor_link?quote=${quoteNumber}&quote_type=${quoteType}&store=${store}`,
        getAuthHeaders()
      );
      console.log("advisor link result");
      console.log(result);
      let info = result.data[0];
      return this.setState(
        {
          financeAmount: result.data.length == 0 ? 0 : result.data[0].total,
          leaseTerm: result.data.length == 0 ? 6 : result.data[0].leaseTerm,
          firstName: info?.firstName,
          lastName: info?.lastName,
          phone: info?.phone,
          email: info?.email,
          street: info?.street,
          unit: info?.unit,
          city: info?.city,
          province: info?.province,
          postalCode: info?.postalCode,
          address: info?.address,
          country: info?.country ?? "Canada",
          manual: 1,
          saving: false,
        },
        () => console.log("this.state", this.state)
      );
    } catch (e) {
      alert(e);
      this.setState({ saving: false });
    }
  };

  saveFinanceAmount = async () => {
    this.setState({ saving: true });
    try {
      var errorFields = [];
      this.financeRequiredFields.forEach((field) => {
        if (
          this.state[field] == null ||
          this.state[field].toString().trim() == ""
        )
          errorFields.push(field);
      });
      if (this.state.financeAmount < this.minimumAmount) {
        errorFields.push("financeAmount");
        alert(`Financing amount cannot be less than $${this.minimumAmount}`);
      }

      if (errorFields.length > 0) {
        //in case there are error fields, highlight errors and scrool to first error
        this.setState({ errorFields, saving: false });
        const section = document.querySelector(`[name = "${errorFields[0]}"]`);
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        return this.setState({
          activeStep: this.state.activeStep + 1,
          saving: false,
        });
      }
    } catch (e) {
      alert(e);
      this.setState({ saving: false });
    }
  };

  handleSubmission = async (e) => {
    e.preventDefault();
    this.setState({ saving: true });

    try {
      const { applicationID } = this.state;
      var errorFields = [];
      // this.basicRequiredFields.forEach((field) => {
      //   if (
      //     this.state[field] == null ||
      //     this.state[field].toString().trim() == ""
      //   )
      //     errorFields.push(field);
      // });
      if (errorFields.length > 0) {
        //in case there are error fields, highlight errors and scrool to first error
        this.setState({ errorFields, saving: false });
        const section = document.querySelector(`[name = "${errorFields[0]}"]`);
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        const body = {
          applicationID,
          submissionTime: moment().format("YYYY-MM-DD HH:mm:ss"),
        };
        const result = await axios.put(
          "/v2/application/submission",
          body,
          getAuthHeaders()
        );
        console.log("result");
        console.log(result);
        this.setState(
          { saving: false, activeStep: this.state.activeStep + 1 },
          () => {
            const section = document.getElementById(`step-form`);
            section.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        );
      }
    } catch (e) {
      alert(e);
      this.setState({ saving: false });
    }
  };

  render() {
    return (
      <PageLayout>
        {this.noHeader == 1 ? <></> : <Header></Header>}
        <Container>
          <MDBox pb={3} mb={20}>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              sx={{ height: "100%", mt: 8 }}
            >
              <Grid item xs={12} lg={8}>
                <form id="step-form" autoComplete="off">
                  <Card sx={{ height: "100%" }}>
                    <MDBox mx={2} mt={-3}>
                      <Stepper
                        style={{ overflow: "scroll" }}
                        activeStep={this.state.activeStep}
                        alternativeLabel
                        // orientation="vertical"
                      >
                        {this.getSteps().map((label) => (
                          <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                          </Step>
                        ))}
                      </Stepper>
                    </MDBox>
                    <MDBox p={3}>
                      <MDBox>
                        {this.getStepContent(this.state.activeStep)}
                        <MDBox
                          mt={2}
                          width="100%"
                          display="flex"
                          justifyContent="space-between"
                        >
                          {this.state.activeStep === 0 ? <MDBox /> : <></>}
                          {this.state.saving ? (
                            <CircularProgress color="info" />
                          ) : this.state.activeStep <
                            this.getSteps().length - 1 ? (
                            <MDButton
                              onClick={(e) => {
                                e.preventDefault();
                                if (
                                  this.state.activeStep == 3 &&
                                  (!this.state.check1 || !this.state.check2)
                                )
                                  return alert(
                                    "Please accept the terms to continue!"
                                  );

                                switch (this.state.activeStep) {
                                  case 0:
                                    this.saveFinanceAmount();
                                    break;
                                  case 1:
                                    this.saveBasicInfo(e);
                                    break;
                                  case 2:
                                    this.saveEmploymentInfo(e);
                                    break;
                                  case 3:
                                    this.handleSubmission(e);
                                  case 4:
                                    this.setState({
                                      activeStep: this.state.activeStep + 1,
                                    });
                                    break;

                                  default:
                                    break;
                                }
                              }}
                              disabled={this.state.saving}
                              type="submit"
                              variant="gradient"
                              color="dark"
                            >
                              {this.state.activeStep == 2 ? "CONTINUE" : "NEXT"}
                            </MDButton>
                          ) : (
                            <div></div>
                          )}
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </Card>
                </form>
              </Grid>
            </Grid>
          </MDBox>
          {/* <BasicInfo errorFields={this.state.errorFields} handleChange={this.handleChange} />
          <AddressInfo errorFields={this.state.errorFields} handleChange={this.handleChange} />
          <br />
          <br />
          <EmploymentInfo errorFields={this.state.errorFields} handleChange={this.handleChange} /> */}
          {/* <Grid sx={{ mb: 20 }} container justifyContent="center">
            <MDBox mt={4} mb={1} sx={{ width: { xs: "100%", md: "50%" } }}>
              <MDButton
                onClick={this.handleSubmit}
                variant="gradient"
                color="info"
                size="large"
                fullWidth
              >
                SUBMIT
              </MDButton>
            </MDBox>
          </Grid> */}
          {/* <Faq /> */}
        </Container>
        {/* <Footer /> */}
      </PageLayout>
    );
  }
}

export default LandingPage;

class ResultPage extends React.Component {
  state = {};

  render() {
    const { firstName, lastName } = this.props;
    return (
      <MDBox>
        <br />
        <h3>Successful Submission {firstName},</h3>
        <br />
        <p style={{ fontSize: 16 }}>
          We have received your application for financing of{" "}
          <b>${this.props.financeAmount}</b> and are reviewing it.
        </p>
        <p style={{ fontSize: 16, marginTop: 8 }}>
          Our in-store <b>Finance Manager</b> will contact you shortly to
          discuss the next steps.
        </p>
        {/* <p style={{ fontSize: 16, marginTop: 8 }}>
          However, our in-store <b>Finance Manager</b> can look into your application and try to
          help you out with other solutions as soon as possible.
        </p> */}
        <br />
        <p style={{ fontSize: 14 }}>Steven Knauss - Finance Manager</p>
        <p style={{ fontSize: 14 }}>
          <a href="mailto:sknaus@camclarkleasing.com">
            sknaus@camclarkleasing.com
          </a>
        </p>
        {/* <p style={{ fontSize: 14 }}>
          <a href="tel:5872288277">(587) 228-8277</a>
        </p> */}
      </MDBox>
    );
  }
}
