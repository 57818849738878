/* eslint-disable react/prefer-stateless-function */

/* eslint-disable */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Dashboard 2 PRO React examples
import PageLayout from "examples/LayoutContainers/PageLayout";

// Pricing page components
import Footer from "layouts/pages/landing/components/Footer";
import Faq from "layouts/pages/landing/components/Faq";
import MDBox from "components/MDBox";
import React from "react";
import withParams from "hoc";

import axios from "../../../axios";
// import moment from "moment";
import { getAuthHeaders } from "../../../helpers";
import { CircularProgress } from "@mui/material";
import Transactions from "components/Transactions";
import Layout from "./components/Layout";
import { decrypt } from "constants";
class CustomerApplicationPage extends React.Component {
  // eslint-disable-line no-use-before-define

  applicationID;
  state = {
    savingID: -1,
    loading: true,
    refreshing: false,
    data: {},
    error: null,
  };

  constructor(props) {
    super(props);
    this.handleApplicationID();
  }

  handleApplicationID = () => {
    try {
      console.log("this.props.applicationID:", this.props.applicationID);
      console.log("this.props.params.id:", this.props.params.id);

      this.applicationID = this.props.applicationID ?? this.props.params.id;
      if (this.props.params.id) {
        this.applicationID = decrypt(this.applicationID);
        if (!this.applicationID) throw "Could not process application ID!";
      }
      this.getData();
    } catch (e) {
      alert(e);
      setTimeout(() => {
        this.setState({ loading: false, refreshing: false, error: e }), 500;
      });
    }
  };

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  handleFileChange = (ID, name, file) =>
    this.setState(
      {
        [name]: file,
        savingID: ID,
      },
      async () => {
        try {
          var form = new FormData();
          form.append("file", this.state[name]);
          form.append("documentID", ID);
          form.append(
            "customerName",
            `${this.state.data.firstName} ${this.state.data.lastName}`
          );
          form.append("name", name);
          const headers = getAuthHeaders();
          headers.headers["Content-Type"] = "multipart/form-data";

          const result = await axios.put(
            `/v2/application/${this.applicationID}/document`,
            form,
            headers
          );

          if (result.data.name) {
            const data = { ...this.state.data };
            console.log("has name");
            for (var i = 0; i < data.documents.length; i++) {
              if (data.documents[i].ID == ID) {
                data.documents[i].fileName = result.data.name;
                break;
              }
            }
            this.setState({ savingID: -1, data });
          } else throw result.data.message;
        } catch (error) {
          this.setState({ savingID: -1 });
          console.log(error);
        }
      }
    );

  getData = async () => {
    try {
      if (!this.applicationID) throw "Could not process ID!";
      this.setState({ refreshing: true });
      const result = await axios.get(
        `/v2/application/${this.applicationID}/status`,
        getAuthHeaders()
      );
      console.log("get documents result");
      console.log(result);
      return this.setState({
        data: result.data,
        loading: false,
        refreshing: false,
      });
    } catch (e) {
      this.setState({ loading: false, refreshing: false });
      alert(e);
    }
  };

  removeFile = async (documentID) => {
    try {
      console.log("remove file called");
      const data = { ...this.state.data };
      data.documents.forEach((doc) => {
        if (doc.ID == documentID) {
          doc.path = null;
          doc.comment = null;
          doc.fileName = null;
          doc.verified = null;
          doc.verifiedByEmail = null;
          doc.verifiedByName = null;
        }
      });
      console.log(data);
      this.setState({ data });
      const result = await axios.delete(
        `/v2/application/${this.applicationID}/document/${documentID}`,
        getAuthHeaders()
      );
      console.log("delete document result");
      console.log(result);
    } catch (e) {
      this.setState({ loading: false });
      alert(e);
    }
  };

  handleFileClick = async (filename) => {
    const headers = getAuthHeaders();
    headers["responseType"] = "blob";

    const response = await axios.get(`/file/${filename}`, headers);
    console.log(response);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  componentDidMount() {
    // setInterval(() => {
    //   this.getData();
    // }, 5000);
  }

  render() {
    if (this.state.error)
      return (
        <MDBox display="flex" justifyContent="center">
          <h3>Error processing link</h3>
        </MDBox>
      );
    if (this.state.loading)
      return (
        <MDBox
          mt={2}
          width="100%"
          height="90vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress />
        </MDBox>
      );
    if (this.props.applicationID)
      return (
        <Transactions
          handleFileClick={this.handleFileClick}
          removeFile={this.removeFile}
          data={this.state.data}
          handleChange={this.handleFileChange}
          savingID={this.state.savingID}
          onRefresh={this.getData}
          refreshing={this.state.refreshing}
        />
      );
    else
      return (
        <Layout>
          <Transactions
            handleFileClick={this.handleFileClick}
            removeFile={this.removeFile}
            data={this.state.data}
            handleChange={this.handleFileChange}
            savingID={this.state.savingID}
            onRefresh={this.getData}
            refreshing={this.state.refreshing}
          />
        </Layout>
      );
  }
}

export default withParams(CustomerApplicationPage);
