// eslint-disable-next-line import/prefer-default-export
export function getAuthHeaders() {
  return {
    headers: {
      "Content-Type": "application/json",
      // "x-auth-token": sessionStorage.getItem("jwt"),
      Authorization: `Bearer ${sessionStorage.getItem("jwt")}`,
    },
  };
}

export function isSystemUser() {
  return JSON.parse(sessionStorage.getItem("user")) != null;
}
export function getUser() {
  return JSON.parse(sessionStorage.getItem("user"));
}
