/* eslint-disable react/prefer-stateless-function */

/* eslint-disable */

// @mui material components

// Pricing page components
import MDBox from "components/MDBox";
import React from "react";
import withParams from "hoc";

import axios from "../../../axios";
// import moment from "moment";
import { getAuthHeaders } from "../../../helpers";
import { CircularProgress } from "@mui/material";
import Transactions from "components/Transactions";
class CustomerApplicationPage extends React.Component {
  // eslint-disable-line no-use-before-define

  applicationID;
  state = {
    savingID: -1,
    loading: true,
    refreshing: false,
    data: {},
  };

  constructor(props) {
    super(props);
    this.applicationID = this.props.applicationID;
  }

  removeFile = async (documentID) => {
    try {
      const data = { ...this.state.data };
      data.documents.forEach((doc) => {
        if (doc.ID == documentID) {
          doc.path = null;
          doc.comment = null;
          doc.fileName = null;
          doc.verified = null;
          doc.verifiedByEmail = null;
          doc.verifiedByName = null;
        }
      });
      console.log(data);
      this.setState({ data });
      const result = await axios.delete(
        `/v2/application/${this.applicationID}/document/${documentID}`,
        getAuthHeaders()
      );
      console.log("delete document result");
      console.log(result);
    } catch (e) {
      this.setState({ loading: false });
      alert(e);
    }
  };

  handleDocumentUpdate = async (status, documentID, comment) => {
    try {
      const user = JSON.parse(sessionStorage.getItem("user"));
      const data = { ...this.state.data };
      data.documents.forEach((doc) => {
        if (doc.ID == documentID) {
          doc.comment = comment;
          doc.verified = status;
          doc.verifiedByName = user.displayName;
          doc.verifiedByEmail = user.userPrincipalName;
          if (this.props.handleDocumentChange) {
            this.props.handleDocumentChange(doc);
          }
        }
      });
      console.log(status);

      this.setState({ data });
    } catch (e) {
      alert(e);
    }
  };

  handleChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
    });

  handleFileChange = (ID, name, file) =>
    this.setState(
      {
        [name]: file,
        savingID: ID,
      },
      async () => {
        try {
          var form = new FormData();
          form.append("file", this.state[name]);
          form.append("documentID", ID);

          const headers = getAuthHeaders();
          headers.headers["Content-Type"] = "multipart/form-data";

          const result = await axios.put(
            `/v2/application/${this.applicationID}/document`,
            form,
            headers
          );

          if (result.data.name) {
            const data = { ...this.state.data };
            for (var i = 0; i < data.documents.length; i++) {
              if (data.documents[i].ID == ID) {
                data.documents[i].fileName = result.data.name;
                break;
              }
            }
            this.setState({ savingID: -1, data });
          } else throw result.data.message;
        } catch (error) {
          this.setState({ savingID: -1 });
          console.log(error);
        }
      }
    );

  getData = async () => {
    try {
      this.setState({ refreshing: true });
      const result = await axios.get(
        `/v2/application/${this.applicationID}/status`,
        getAuthHeaders()
      );
      console.log("get documents result");
      console.log(result);
      return this.setState({
        data: result.data,
        loading: false,
        refreshing: false,
      });
    } catch (e) {
      this.setState({ loading: false, refreshing: false });
      alert(e);
    }
  };

  handleFileClick = async (filename) => {
    const headers = getAuthHeaders();
    headers["responseType"] = "blob";

    const response = await axios.get(`/file/${filename}`, headers);
    console.log(response);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename); //or any other extension
    document.body.appendChild(link);
    link.click();
  };

  componentDidMount() {
    this.getData();
  }

  render() {
    if (this.state.loading)
      return (
        <MDBox
          mt={2}
          width="100%"
          display="flex"
          justifyContent="space-between"
        >
          <CircularProgress />
        </MDBox>
      );

    return (
      <Transactions
        handleFileClick={this.handleFileClick}
        removeFile={this.removeFile}
        data={this.state.data}
        handleChange={this.handleFileChange}
        handleDocumentUpdate={this.handleDocumentUpdate}
        savingID={this.state.savingID}
        refreshing={this.state.refreshing}
        onRefresh={this.getData}
      />
    );
  }
}

export default withParams(CustomerApplicationPage);
