// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import { Button, CircularProgress, Grid } from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import FormField from "layouts/pages/account/components/FormField";
import { isSystemUser } from "../../helpers";
import { AttachFile } from "@mui/icons-material";

function Transaction({
  ID,
  shortName,
  comment,
  color,
  icon,
  name,
  value,
  handleChange,
  handleFileClick,
  handleDocumentUpdate,
  removeFile,
  saving,
  fileName,
  verified,
  verifiedByName,
}) {
  return (
    <MDBox
      key={name}
      component="li"
      p={2}
      my={2}
      style={{ border: "1px solid rgb(242, 242, 242)", borderRadius: 8 }}
    >
        <Grid container justifyContent={'space-between'} spacing={2}>
          <Grid item xs={12} md>
          <MDBox display="flex" alignItems="center">
          <MDBox mr={2}>
            <MDButton
              variant="outlined"
              color={color}
              iconOnly
              circular
              disabled
            >
              <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
            </MDButton>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDTypography variant="button" fontWeight="medium">
              {name}
            </MDTypography>
            <MDTypography
              variant="caption"
              color={color}
              fontWeight="regular"
              textGradient
            >
              {value}
            </MDTypography>
          </MDBox>
        </MDBox>
          </Grid>
          <Grid item xs={12} md={5}>       
          <Grid container justifyContent={'flex-end'}>
            <Grid item>
            {
          saving ? (
            <CircularProgress />
          ) : fileName !== null ? (
            <MDBox display="flex">
              <MDButton  onClick={handleFileClick || null} variant='outlined' color='info' startIcon={<AttachFile/>}>
              {fileName.slice(0, 10)}...
                  {fileName.substring(fileName.length - 5)}
              </MDButton>
              {/* <MDBox
                onClick={handleFileClick || null}
                sx={{ maxWidth: 150, px: 2, cursor: "pointer" }}
                color="info"
                // bgColor="info"
                variant="gradient"
                borderRadius={8}
              >
                <MDTypography
                  variant="caption"
                  fontWeight="regular"
                  color="info"
                >
                  {fileName.slice(0, 10)}...
                  {fileName.substring(fileName.length - 5)}
                </MDTypography>
              </MDBox> */}
              {verified!==1 && <MDButton
                onClick={removeFile}
                variant="text"
                color="error"
                sx={{ padding: 0 }}
              >
                REMOVE
              </MDButton>}
            </MDBox>
          ) : (
            value.toLowerCase() !== "success" && (
              <MDBox display="flex" flexDirection="column">
                <input
                  onChange={(e) =>
                    handleChange(ID, shortName, e.target.files[0])
                  }
                  type="file"
                  id={`file-${name}`}
                />
                <label htmlFor={`file-${name}`}>
                  <Button
                    labelFor={`file-${name}`}
                    variant="gradient"
                    color="info"
                    size="small"
                    style={{ display: "none" }}
                    containerElement="label"
                  >
                    <Icon sx={{ fontWeight: "bold", marginRight: 2 }}>
                      attach_file
                    </Icon>{" "}
                    Choose File{" "}
                  </Button>
                </label>
              </MDBox>
            )
          )
        }</Grid> 
          </Grid>
          </Grid>
        </Grid>

   

      {fileName !== null && (
        <MDBox style={{ marginTop: 16, marginBottom: 16 }}>
          {verified !== null && !isSystemUser() && (
            <MDBox display="flex" alignItems="center" style={{ marginTop: 8 }}>
              <MDTypography
                style={{ marginLeft: 4 }}
                variant="caption"
                fontWeight="regular"
                color={verified ? "success" : "error"}
              >
                {verified
                  ? "Document accepted."
                  : "Document was not accepeted."}
              </MDTypography>
            </MDBox>
          )}
          {comment && comment.trim() !== "" && !isSystemUser() && (
            <MDTypography
              style={{ marginLeft: 4 }}
              variant="subtitle2"
              fontWeight="regular"
              color="dark"
            >
              {comment}
            </MDTypography>
          )}
          {isSystemUser() && (
            <MDBox
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              style={{ marginTop: 16 }}
            >
              <h5>Is the document accepted ? </h5>
              <MDButton
                variant={
                  verified !== null && !verified ? "contained" : "outlined"
                }
                color="error"
                style={{ marginLeft: 8 }}
                circular
                iconOnly
                onClick={() => handleDocumentUpdate(0, comment)}
              >
                <Icon>clear</Icon>
              </MDButton>
              <MDButton
                variant={
                  verified !== null && verified ? "contained" : "outlined"
                }
                style={{ marginLeft: 8 }}
                color="success"
                circular
                iconOnly
                onClick={() => handleDocumentUpdate(1, comment)}
              >
                <Icon>check</Icon>
              </MDButton>
            </MDBox>
          )}
          {isSystemUser() && (
            <FormField
              onBlur={(e) => {
                handleDocumentUpdate(verified, e.target.value);
              }}
              defaultValue={comment}
              name="comment"
              label="Comment"
            />
          )}
        </MDBox>
      )}
    </MDBox>
  );
}

// Typechecking props of the Transaction
Transaction.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  shortName: PropTypes.string.isRequired,
  fileName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  ID: PropTypes.number.isRequired,
  saving: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleFileClick: PropTypes.func.isRequired,
  handleDocumentUpdate: PropTypes.func.isRequired,
  removeFile: PropTypes.func.isRequired,
};

export default Transaction;
