import React, { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import Avatar from "@mui/material/Avatar";
import CssBaseline from "@mui/material/CssBaseline";
import { CircularProgress, Button, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Microsoft } from "@mui/icons-material";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "../../../../axios";

const theme = createTheme();

function LoginPage(props) {
  const siteName = "Cam Clark Finance";
  const [loading, setLoading] = useState(true);
  const { instance } = useMsal();

  useEffect(() => {
    const account = instance.getActiveAccount();
    console.log("account in login:", account);
    console.log("loading:", loading);

    if (account) {
      setLoading(true);
      // props.history.push("/");
      console.log("has account ");
      if (account.idToken) authenticateUser(account.idToken, false);
      else setLoading(false);
    } else {
      setLoading(false);
    }
  }, [instance]);

  const handleLogin = () => {
    setLoading(true);
    instance.loginRedirect().catch((e) => {
      console.error(e);
      setLoading(false);
    });
  };

  const authenticateUser = async (accessToken, remember) => {
    try {
      console.log("accessToken", accessToken);
      const result = await axios.post(
        "/v2/auth",
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      console.log("login result");
      console.log(result);

      localStorage.clear();
      sessionStorage.clear();

      if (remember) {
        localStorage.setItem("jwt", accessToken);
        localStorage.setItem("user", JSON.stringify(result.data.user));
      }
      sessionStorage.setItem("jwt", accessToken);
      sessionStorage.setItem("user", JSON.stringify(result.data.user));
      setLoading(false);
      props.history.push("/dashboard");

      props.onSuccess();
      // this.props.history.push("/");
    } catch (error) {
      console.log("error");
      console.log(error);
      console.log(error.response);
      setLoading(false);
      if (error.response) alert(error.response.data.message);
      else alert(error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            height: "90vh", // Make the Box fill the entire viewport height
            display: "flex", // Enable flexbox
            flexDirection: "column", // Stack children vertically
            justifyContent: "center", // Center vertically in the container
            alignItems: "center", // Center horizontally in the container
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography
            style={{ textAlign: "center" }}
            component="h1"
            variant="h5"
          >
            Cam Clark Automotive Group
          </Typography>
          <Typography
            style={{ textAlign: "center", marginTop: 16 }}
            component="h1"
            variant="h5"
          >
            {siteName}
          </Typography>
          <Button
            startIcon={<Microsoft />}
            onClick={handleLogin}
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In with Microsoft
          </Button>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default LoginPage;
