/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import PropTypes from "prop-types";

// @material-ui core components
import {
  Grid,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { getTerms } from "../../../../../constants";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

function FinanceAmount({
  handleChange,
  errorFields,
  data,
  allowAmountChange,
  loading,
}) {
  console.log("loading");
  console.log(loading);

  console.log("finance amonut data");
  console.log(data);
  if (loading) return <p>Loading ...</p>;
  return (
    <>
      {!data.allowAmountChange ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <strong>Finance Amount : </strong>
            {parseFloat(data.financeAmount).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </Grid>
          <Grid item xs={12} sm={6}>
            <strong>Lease Term : </strong>
            {getTerms()
              .filter((term) => term.value == data.leaseTerm)
              .map((term) => (
                <span>{`${term.name} at ${term.interestRate}%`}</span>
              ))}
          </Grid>
        </Grid>
      ) : (
        <>
          <MDBox p={3}>
            <MDTypography variant="h5">Total Amount for Financing</MDTypography>
          </MDBox>
          <MDBox component="form" pb={3} px={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  readOnly={!allowAmountChange}
                  InputProps={{
                    readOnly: !allowAmountChange,
                    disabled: !allowAmountChange,
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputMode: "numeric",
                    pattern: "[0-9]",
                    type: "number",
                  }}
                  defaultValue={data.financeAmount}
                  required
                  error={errorFields.indexOf("financeAmount") !== -1}
                  name="financeAmount"
                  onChange={allowAmountChange ? handleChange : null}
                  label="Finance Amount"
                  placeholder=""
                  type={"number"}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl
                  required
                  variant="standard"
                  sx={{ pt: 1, minWidth: 120 }}
                  fullWidth
                  readOnly={!allowAmountChange}
                  InputProps={{
                    readOnly: !allowAmountChange,
                  }}
                >
                  <InputLabel id="province-label">Lease Term</InputLabel>
                  <Select
                    readOnly={!allowAmountChange}
                    InputProps={{
                      readOnly: !allowAmountChange,
                    }}
                    fullWidth
                    onChange={handleChange}
                    labelId="leaseTerm-label"
                    error={errorFields.indexOf("leaseTerm") !== -1}
                    name="leaseTerm"
                    label="Lease Term"
                    defaultValue={data.leaseTerm}
                  >
                    {getTerms().map((term) => (
                      <MenuItem
                        value={term.value}
                      >{`${term.name} at ${term.interestRate}%`}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} sm={6}>
            <FormField
              defaultValue={data.employmentPosition}
              required
              error={errorFields.indexOf("employmentPosition") !== -1}
              name="employmentPosition"
              onBlur={handleChange}
              label="Position"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue={data.employmentDuration ? data.employmentDuration : ""}
              options={[
                "less than 6 months",
                "6 months +",
                "8 months +",
                "1 year +",
                "2 years +",
                "5 years +",
              ]}
              renderInput={(params) => (
                <FormField
                  required
                  {...params}
                  error={errorFields.indexOf("employmentDuration") !== -1}
                  name="employmentDuration"
                  onBlur={handleChange}
                  label="How long have you been working at current employer?"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue={data.employmentType ? data.employmentType : ""}
              options={[
                "Full-time Employment",
                "Part-time Employment",
                "Self-Employed",
                "Retired",
                "Student",
                "Disablity",
                "Seasonal Worker",
                "Unemployed with income",
                "Unemployed without income",
              ]}
              renderInput={(params) => (
                <FormField
                  {...params}
                  required
                  error={errorFields.indexOf("employmentType") !== -1}
                  name="employmentType"
                  onBlur={handleChange}
                  label="What is your employment type?"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue={data.annualIncome ? data.annualIncome : ""}
              options={[
                "$30,000+",
                "$40,000+",
                "$50,000+",
                "$60,000+",
                "$70,000+",
                "$80,000+",
                "$90,000+",
                "$100,000+",
                "$150,000+",
                "$200,000+",
              ]}
              renderInput={(params) => (
                <FormField
                  {...params}
                  required
                  error={errorFields.indexOf("annualIncome") !== -1}
                  name="annualIncome"
                  onBlur={handleChange}
                  label="What is your annual income?"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue={data.householdIncome ? data.householdIncome : ""}
              options={[
                "$30,000+",
                "$40,000+",
                "$50,000+",
                "$60,000+",
                "$70,000+",
                "$80,000+",
                "$90,000+",
                "$100,000+",
                "$150,000+",
                "$200,000+",
              ]}
              renderInput={(params) => (
                <FormField
                  {...params}
                  required
                  error={errorFields.indexOf("householdIncome") !== -1}
                  name="householdIncome"
                  onBlur={handleChange}
                  label="What is your household income?"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              defaultValue={data.housingType ? data.housingType : ""}
              options={["Own", "Rent", "Living with someone"]}
              renderInput={(params) => (
                <FormField
                  required
                  {...params}
                  error={errorFields.indexOf("housingType") !== -1}
                  name="housingType"
                  onBlur={handleChange}
                  label="What is your housing type?"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          </Grid> */}
            </Grid>
          </MDBox>
        </>
      )}
    </>
  );
}
FinanceAmount.defaultProps = {
  data: {},
  allowAmountChange: true,
  loading: true,
};
FinanceAmount.propTypes = {
  handleChange: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  allowAmountChange: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};
export default FinanceAmount;
