/* eslint-disable react/prefer-stateless-function */

/* eslint-disable */

// @mui material components
import {
  Grid,
  Dialog,
  AppBar,
  Toolbar,
  Card,
  CircularProgress,
  IconButton,
  Slide,
} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

import React from "react";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDTypography from "components/MDTypography";
import {
  KeyboardArrowLeft,
  Close as CloseIcon,
  Save as SaveIcon,
  Receipt as ReceiptIcon,
  Check,
  FileDownload,
} from "@mui/icons-material";
import BasicInfo from "layouts/pages/landing/components/BasicInfo";
import EmploymentInfo from "layouts/pages/landing/components/EmploymentInfo";
import CreditInfo from "./components/CreditInfo";
import MDButton from "components/MDButton";
import Timeline from "./components/Timeline";
import { getAuthHeaders } from "helpers";

import axios from "../../../axios";
import withParams from "hoc";
import moment from "moment";

import CustomerDocuments from "./documents";
import { getTerms } from "constants";
import MDSnackbar from "components/MDSnackbar";
import { decrypt } from "constants";
import { getUser } from "helpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Details extends React.Component {
  applicationID;
  skipFieldsForLogs = [
    "ID",
    "dob",
    "DOB",
    "statusChangeTime",
    "submitted",
    "created",
    "partsQuoteNo",
    "storeName",
    "serialNo",
  ];
  basicRequiredFields = [
    "firstName",
    "lastName",
    "phone",
    "email",
    "dob",
    "address",
    "street",
    "city",
    "province",
    "postalCode",
    "country",
  ];
  employmentRequiredFields = [
    "employer",
    "employerPhone",
    "employmentPosition",
    "employmentDuration",
    "employmentType",
    "annualIncome",
    "householdIncome",
    "housingType",
    "rentAmount",
  ];
  state = {
    data: null,
    loading: true,
    loadingAdvisorInfo: true,
    saving: false,
    dialogOpen: false,
    equifaxDialogOpen: false,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    dob: null,
    sin: "",
    address: "",
    unit: "",
    street: "",
    city: "",
    province: "",
    postalCode: "",
    country: "Canada",
    employer: "",
    employerPhone: "",
    employmentPosition: "",
    employmentDuration: "",
    employmentType: "",
    annualIncome: "",
    householdIncome: "",
    housingType: "",
    rentAmount: "",
    submitted: null,
    approve: null,
    approvedCredit: 0,
    comments: null,
    creditRemarks: "",
    creditScore: null,
    status: null,
    statusChangeTime: null,
    financeAmount: null,
    updatedDocuments: {},
    errorFields: [],
    stores: [],
    showAlert: false,
    alertTitle: "",
    alertContent: "",
    alertColor: "info",
    error: null,
  };
  constructor(props) {
    super(props);
    this.handleApplicationIDProp();
  }
  componentDidMount() {
    console.log("this.props", this.props);
    this.getStores();
  }

  getStores = async () => {
    try {
      const result = await axios.get(`/v2/store/`, getAuthHeaders());
      console.log("stores result");
      console.log(result);
      if (result.data.length == 0) throw "No Stores Found ";
      this.setState({
        stores: result.data,
      });
    } catch (e) {
      console.log("couldnt get stores");
    }
  };

  getStoreName = (serialNo) => {
    var store = this.state.stores.filter((store) => store.serial == serialNo);
    if (store.length > 0) return store[0].name;
    else return "";
  };

  handleApplicationIDProp = () => {
    try {
      if (isNaN(this.props.params.id))
        this.applicationID = decrypt(this.props.params.id);
      else this.applicationID = this.props.params.id;
      if (!this.applicationID) throw "Could not process application ID!";
      this.getData();
    } catch (e) {
      console.log("handleApplicationIDProp Error");

      setTimeout(() => {
        alert(e);
        this.setState({ error: e });
        500;
      });

      console.log(e);
    }
  };

  handleDateChange = (date) => {
    var data = this.state.data;
    data.dob = moment(date);
    this.setState({
      data,
      errorFields: this.state.errorFields.filter((field) => field != "dob"),
    });
  };

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value });
  handleLeaseChange = (e) => {
    let terms = getTerms();
    var selectedTerm = terms.filter((term) => term.value == e.target.value);
    if (selectedTerm.length > 0)
      this.setState({
        leaseTerm: e.target.value,
        interestRate: selectedTerm[0].interestRate,
      });
  };

  handleShowAlert = ({ title, content, color }) => {
    this.setState(
      {
        alertTitle: title,
        alertContent: content,
        alertColor: color,
        showAlert: true,
      },
      () => {
        setTimeout(() => {
          this.setState({
            alertTitle: null,
            alertContent: null,
            alertColor: null,
            showAlert: false,
          });
        }, 3000);
      }
    );
  };

  getData = async () => {
    try {
      const res = await axios.get(
        `/v2/application/${this.applicationID}`,
        getAuthHeaders()
      );

      console.log("res.data");
      console.log(res.data);
      if (res.data.length > 0) {
        this.setState(
          {
            firstName: res.data[0].firstName,
            lastName: res.data[0].lastName,
            phone: res.data[0].phone,
            email: res.data[0].email,
            dob: moment(res.data[0].DOB),
            sin: res.data[0].sin,
            address: res.data[0].street,
            unit: res.data[0].unit,
            street: res.data[0].street,
            city: res.data[0].city,
            province: res.data[0].province,
            postalCode: res.data[0].postalCode,
            country: res.data[0].country,
            employer: res.data[0].employer,
            employerPhone: res.data[0].employerPhone,
            employmentPosition: res.data[0].employmentPosition,
            employmentDuration: res.data[0].employmentDuration,
            employmentType: res.data[0].employmentType,
            annualIncome: `$${parseInt(
              res.data[0].annualIncome
            ).toLocaleString()}+`,
            householdIncome: `$${parseInt(
              res.data[0].householdIncome
            ).toLocaleString()}+`,
            housingType: res.data[0].housingType,
            rentAmount: res.data[0].rentAmount,
            submitted: moment(res.data[0].submitted),
            approve: res.data[0].approve,
            approvedCredit: res.data[0].approvedCredit,
            comments: res.data[0].comments,
            creditRemarks: res.data[0].creditRemarks,
            creditScore: res.data[0].creditScore,
            status: res.data[0].status,
            financeAmount: res.data[0].financeAmount,
            leaseTerm: res.data[0].leaseTerm,
            interestRate: res.data[0].interestRate,
            statusChangeTime: moment(res.data[0].statusChangeTime),
            equifaxInfo: res.data[0].equifaxInfo,
          },
          () => {
            if (
              (res.data[0].partsQuoteNo || res.data[0].roNo) &&
              res.data[0].serialNo
            )
              this.getAvisorInfo({
                quoteNumber: res.data[0].partsQuoteNo ?? res.data[0].roNo,
                quoteType: res.data[0].roNo ? "ro" : "parts",
                serialNo: res.data[0].serialNo,
              });
          }
        );
      }
      res.data[0].annualIncome = this.state.annualIncome;
      res.data[0].householdIncome = this.state.householdIncome;
      res.data[0].dob = moment(res.data[0].DOB);
      this.setState({ data: res.data[0], loading: false });
    } catch (error) {
      console.log("error", error);
      this.setState({ loading: false });
    }
  };

  getChangedFields = () => {
    var changedFields = [];
    console.log("change fields check start");
    Object.keys(this.state.data).forEach((item) => {
      if (this.skipFieldsForLogs.indexOf(item) !== -1) {
        console.log("skip : " + item);
        return;
      }

      if (item == "status") {
        var status = this.state.data[item];
        this.state.data[item] = this.state[item];
        this.state[item] = status;
      }
      if (this.state.data[item] != this.state[item]) {
        //add from and to field
        changedFields.push({
          field: [item],
          from: this.state.data[item],
          to: this.state[item],
        });
        console.log(`${this.state.data[item]}!=${this.state[item]}`);
      }
    });
    if (
      this.state.data.dob.format("YYYY-MM-DD") !=
      this.state.dob.format("YYYY-MM-DD")
    ) {
      // changedFields.push("dob");
      changedFields.push({
        field: "DOB",
        from: this.state.dob.format("YYYY-MM-DD"),
        to: this.state.data.dob.format("YYYY-MM-DD"),
      });
    }
    console.log("changedFields", changedFields);

    console.log("change fields check end");
    return changedFields;
  };

  updateInfo = async () => {
    this.setState({ saving: true });

    try {
      let {
        firstName,
        lastName,
        phone,
        email,
        dob,
        sin,
        unit,
        street,
        city,
        province,
        postalCode,
        country,
        employer,
        employerPhone,
        employmentPosition,
        employmentDuration,
        employmentType,
        annualIncome,
        householdIncome,
        housingType,
        rentAmount,
        comments,
        financeAmount,
        leaseTerm,
        interestRate,
      } = this.state;

      dob = this.state.data.dob;

      let changedFields = this.getChangedFields().filter(
        (item) =>
          item.field != "statusChangeName" &&
          item.field != "statusChangeEmail" &&
          item.field != "undefined"
      );

      var errorFields = [];
      this.basicRequiredFields.forEach((field) => {
        if (
          this.state[field] == null ||
          this.state[field].toString().trim() == ""
        )
          errorFields.push(field);
      });
      this.employmentRequiredFields.forEach((field) => {
        if (
          this.state[field] == null ||
          this.state[field].toString().trim() == ""
        )
          errorFields.push(field);
      });

      if (errorFields.length > 0) {
        //in case there are error fields, highlight errors and scrool to first error
        this.setState({ errorFields, saving: false });
        const section = document.querySelector(`[name = "${errorFields[0]}"]`);
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      } else {
        const body = {
          applicationID: this.applicationID,
          firstName,
          lastName,
          phone,
          email,
          dob: moment(dob).format("YYYY-MM-DD HH:mm:ss"),
          sin,
          unit,
          street,
          city,
          province,
          postalCode,
          country,
          employer,
          employerPhone,
          employmentDuration,
          employmentPosition,
          employmentType,
          annualIncome: parseFloat(
            annualIncome.replace(",", "").replace("$", "")
          ),
          householdIncome: parseFloat(
            householdIncome.replace("$", "").replace(",", "")
          ),
          housingType,
          rentAmount,
          comments,
          changedFields,
          financeAmount,
          leaseTerm,
          interestRate,
        };

        const result = await axios.put(
          "/v2/application/",
          body,
          getAuthHeaders()
        );
        console.log("result");
        console.log(result);
        this.getData();
        this.updateDocuments();
        this.handleShowAlert({
          title: "Updated",
          content: "Application udpated successfully.",
        });
        return this.setState({ saving: false });
      }
    } catch (e) {
      this.setState({ saving: false });
      if (e.response) alert(e.response.data.message);
      else alert(e);
    }
  };

  handleApproval = async (approved) => {
    const shouldProceed = window.confirm(
      `Are you sure you want to ${
        approved ? "Approve" : "Reject"
      } the application?`
    );
    if (!shouldProceed) return;

    try {
      const user = JSON.parse(sessionStorage.getItem("user"));
      this.setState({
        saving: true,
        data: {
          ...this.state.data,
          status: approved,
          statusChangeTime: moment(),
          statusChangeName: user.displayName,
          statusChangeEmail: user.userPrincipalName,
        },
      });
      const body = {
        applicationID: this.applicationID,
        status: approved,
        comments: this.state.comments,
        financeAmount: this.state.financeAmount,
        statusChangeTime: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      const result = await axios.put(
        "/v2/application/status",
        body,
        getAuthHeaders()
      );
      console.log("result");
      console.log(result);
      // alert(`Application ${approved ? "Approved" : "Rejectd"}`);
      this.updateInfo();
    } catch (e) {
      alert(e);
      this.setState({ saving: false, status: null, statusChangeTime: null });
    }
  };

  updateDocuments = async () => {
    try {
      const user = JSON.parse(sessionStorage.getItem("user"));

      const body = {
        documents: Object.values(this.state.updatedDocuments),
        date: moment().format("YYYY-MM-DD HH:mm:ss"),
      };
      const result = await axios.put(
        `/v2/application/${this.applicationID}/documents/status`,
        body,
        getAuthHeaders()
      );

      this.setState({ saving: false });
      // alert(`Documents Updated.`);
    } catch (e) {
      alert(e.response.data.message);
      console.log(e);
      this.setState({ saving: false, status: null, statusChangeTime: null });
    }
  };

  handleDocumentChange = (document) => {
    this.state.updatedDocuments[document.ID] = document;
    console.log("this.state.updatedDocuments");
    console.log(this.state.updatedDocuments);
  };

  handleAddressChange = ({
    address,
    street,
    city,
    province,
    postalCode,
    country,
  }) => {
    if (!street || street.trim() == "") {
      return;
    }
    this.setState({ address, street, city, province, postalCode, country });
  };

  getAvisorInfo = async ({ quoteNumber, quoteType, serialNo }) => {
    this.setState({ loadingAdvisorInfo: true });
    try {
      const result = await axios.get(
        `/v2/application/advisor_link?quote=${quoteNumber}&quote_type=${quoteType}&store=${serialNo}`,
        getAuthHeaders()
      );
      console.log("advisor link result");
      console.log(result);
      return this.setState(
        {
          advisorInfo: result.data[0],
        },
        () => console.log("this.state", this.state)
      );
    } catch (e) {
      alert(e);
      this.setState({ loadingAdvisorInfo: false });
    }
  };

  downloadContract = async () => {
    this.setState({ downloading: true });
    const headers = getAuthHeaders();
    headers["responseType"] = "blob";

    console.log(`/v2/application/${this.applicationID}/form`);
    const response = await axios.get(
      `/v2/application/${this.applicationID}/form`,
      headers
    );
    console.log("download form response");
    console.log(response);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "contract.pdf"); //or any other extension
    document.body.appendChild(link);
    link.click();
    this.setState({ downloading: false });
  };

  render() {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mt={2} mb={2}>
          {this.state.error && (
            <h3 style={{ align: "center" }}>Error in file</h3>
          )}
          {!this.state.loading && !this.state.error && (
            <Grid container>
              <MDButton
                onClick={() => {
                  this.props.navigate(-1);
                }}
                variant="text"
                color="info"
                startIcon={<KeyboardArrowLeft />}
              >
                BACK
              </MDButton>
              <MDTypography>Applications Details</MDTypography>
            </Grid>
          )}
          {this.state.loading && (
            <Grid
              container
              style={{ height: "80vh" }}
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress />
            </Grid>
          )}
          {!this.state.loading && (
            <MDBox my={5}>
              <Grid
                container
                justifyContent={"space-between"}
                style={{ padding: 4 }}
              >
                {this.state.data?.serialNo && (
                  <p>
                    <b>Store</b> :{" "}
                    {this.getStoreName(this.state.data?.serialNo)}
                  </p>
                )}
                {/* {this.state.data?.storeName && (
                  <p>
                    <b>Store</b> : {this.state.data?.storeName}
                  </p>
                )} */}
                {this.state.advisorInfo?.advisor && (
                  <p>
                    <b>Advisor</b> : {this.state.advisorInfo?.advisor}
                  </p>
                )}
              </Grid>
              <MDBox mt={2}>
                <Card>
                  <BasicInfo
                    data={this.state.data}
                    errorFields={this.state.errorFields}
                    handleAddressChange={this.handleAddressChange}
                    handleChange={this.handleChange}
                    handleDateChange={this.handleDateChange}
                  />
                </Card>
              </MDBox>
              <MDBox mt={5}>
                <Card>
                  <EmploymentInfo
                    data={this.state.data}
                    errorFields={this.state.errorFields}
                    handleChange={this.handleChange}
                  />
                </Card>
              </MDBox>
              <MDBox mt={5}>
                <Card>
                  <CreditInfo
                    data={this.state.data}
                    handleChange={this.handleChange}
                    handleLeaseChange={this.handleLeaseChange}
                    handleApproval={(approved) => this.handleApproval(approved)}
                    saving={this.saving}
                  />
                </Card>
              </MDBox>
              <MDBox mt={5}>
                <Card>
                  <CustomerDocuments
                    handleDocumentChange={(document) =>
                      this.handleDocumentChange(document)
                    }
                    applicationID={this.applicationID}
                  />
                </Card>
              </MDBox>
              {(this.state.data.status == null &&
                this.state.data.statusChangeTime == null) ||
                (1 && (
                  <Grid container mt={5} justifyContent="center">
                    {this.state.saving ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <MDButton
                          startIcon={<SaveIcon />}
                          onClick={this.updateInfo}
                          size="large"
                          variant="gradient"
                          color="info"
                        >
                          Update
                        </MDButton>
                        <MDButton
                          startIcon={<ReceiptIcon />}
                          variant="outlined"
                          sx={{ marginLeft: 4 }}
                          onClick={() => this.setState({ dialogOpen: true })}
                          size="large"
                          color="secondary"
                        >
                          View Logs
                        </MDButton>
                        {this.state.downloading ? (
                          <CircularProgress style={{ marginLeft: 16 }} />
                        ) : (
                          <MDButton
                            startIcon={<FileDownload />}
                            variant="outlined"
                            sx={{ marginLeft: 4 }}
                            onClick={this.downloadContract}
                            size="large"
                            color="primary"
                          >
                            Download Contract
                          </MDButton>
                        )}
                      </>
                    )}
                  </Grid>
                ))}
              {this.state.data.statusChangeTime == null && (
                <MDButton
                  startIcon={<FileDownload />}
                  variant="outlined"
                  sx={{ marginLeft: 4, marginTop: 4 }}
                  onClick={this.downloadContract}
                  size="large"
                  color="primary"
                >
                  Download Contract
                </MDButton>
              )}
            </MDBox>
          )}
        </MDBox>
        <Grid container justifyContent={"center"}></Grid>
        <Footer />
        <Dialog
          fullScreen
          open={this.state.dialogOpen}
          onClose={() => this.setState({ dialogOpen: false })}
          TransitionComponent={Transition}
        >
          <AppBar color="info" sx={{ position: "relative" }}>
            <Toolbar
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <h3>Logs</h3>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => this.setState({ dialogOpen: false })}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <Timeline
            id={this.applicationID}
            created={this.state?.data?.created}
          />
        </Dialog>
        <MDSnackbar
          open={this.state.showAlert}
          title={this.state.alertTitle}
          icon={<Check />}
          content={this.state.alertContent}
          dateTime={"Now"}
          color="success"
          close={() => {
            this.setState({ showAlert: false });
          }}
        />
      </DashboardLayout>
    );
  }
}

export default withParams(Details);
