/* eslint-disable react/no-unused-prop-types */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";

// @material-ui core components
// import Card from "@mui/material/Card";
import {Grid,TextField} from "@mui/material";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { MobileDatePicker } from "@mui/x-date-pickers/";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";
import AddressInfo from "layouts/pages/landing/components/AddressInfo";
import moment from "moment";
import InputMask from 'react-input-mask';

const PhoneInput = (props) => (
  <InputMask  mask="+1 (999) 999-9999" defaultValue={props.defaultValue} onChange={props.onChange} onBlur={props.onBlur}>
    {(inputProps) => <FormField {...inputProps} {...props} type="tel" disableUnderline />}
  </InputMask>
);


function BasicInfo({ handleChange,handleDateChange, handleAddressChange, errorFields, data }) {
  console.log('dob in basic info');
  console.log(data);
  return (
    // <Card id="basic-info" sx={{ overflow: "visible" }}>
    <>
      <MDBox p={3}>
        <MDTypography variant="h5">Personal Info</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              defaultValue={data.firstName}
              required
              error={errorFields.indexOf("firstName") !== -1}
              name="firstName"
              onBlur={handleChange}
              label="First Name"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              defaultValue={data.lastName}
              required
              error={errorFields.indexOf("lastName") !== -1}
              name="lastName"
              onBlur={handleChange}
              label="Last Name"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              defaultValue={data.email}
              required
              error={errorFields.indexOf("email") !== -1}
              label="Email"
              name="email"
              onBlur={handleChange}
              placeholder="example@email.com"
              inputProps={{ type: "email" }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PhoneInput
              defaultValue={data.phone}
              required
              error={errorFields.indexOf("phone") !== -1}
              name="phone"
              onBlur={handleChange}
              label="Phone Number"
            />
            {/* <FormField
              defaultValue={data.phone}
              required
              error={errorFields.indexOf("phone") !== -1}
              name="phone"
              onBlur={handleChange}
              label="Phone Number"
              placeholder="735 631 620"
            /> */}
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
              <MobileDatePicker
              closeOnSelect={true}
                disableCloseOnSelect={false}
                showToolbar={false}
                required
                inputFormat="yyyy-MM-DD"
                name="dob"
                inputProps={{ name:"dob" }}
                disableFuture
                views={["year", "month", "day"]}
                value={data.dob ? moment(data.dob) : null}
                onChange={(date) =>
                  handleDateChange(date)
                }
                label="Date of Birth *"
                renderInput={(params) => <TextField {...params} name='dob' error={errorFields.indexOf("dob") !== -1}  variant='standard' fullWidth/>}
              />
                {/* <FormField
                  required
                  error={errorFields.indexOf("dob") !== -1}
                  name="dob"
                  defaultValue={data.dob ?? null}
                  onBlur={handleChange}
                  onChange={handleChange}
                  label="Date of Birth"
                  inputProps={{ type: "date", defaultValue: data.dob }}
                /> */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  defaultValue={data.sin}
                  name="sin"
                  label="SIN (optional)"
                  placeholder="000-000-000"
                />
              </Grid>
              <Grid item xs={12}>
                <AddressInfo
                  data={data}
                  handleAddressChange={handleAddressChange}
                  errorFields={errorFields}
                  handleChange={handleChange}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MDBox>
    </>
    // </Card>
  );
}

BasicInfo.defaultProps = {
  data: {},
};

BasicInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleDateChange: PropTypes.func.isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
};

export default BasicInfo;
