import CryptoJS from "crypto-js";
const SALT = "ccg_finance";
function encrypt(plainText) {
  var b64 = CryptoJS.AES.encrypt(plainText, SALT).toString();
  var e64 = CryptoJS.enc.Base64.parse(b64);
  var eHex = e64.toString(CryptoJS.enc.Hex);
  return eHex;
}

function decrypt(cipherText) {
  if (!cipherText) return null;
  try {
    var reb64 = CryptoJS.enc.Hex.parse(cipherText);
    var bytes = reb64.toString(CryptoJS.enc.Base64);
    var decrypt = CryptoJS.AES.decrypt(bytes, SALT);
    var plain = decrypt.toString(CryptoJS.enc.Utf8);
    return plain;
  } catch (e) {
    console.log(e);
    return null;
  }
}

function getTerms() {
  return [
    { name: "6 Months", value: 6, interestRate: 9.99, fee: 99 },
    { name: "1 Year", value: 12, interestRate: 11.99, fee: 99 },
    { name: "2 Years", value: 24, interestRate: 13.99, fee: 149 },
    { name: "3 Years", value: 36, interestRate: 14.99, fee: 249 },
  ];
}

export { encrypt, decrypt, getTerms };
