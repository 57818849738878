/* eslint-disable react/no-unused-prop-types */
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import PropTypes from "prop-types";

// @material-ui core components
// import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Settings page components
// import FormField from "layouts/pages/account/components/FormField";
// import { CircularProgress } from "@mui/material";

function Identification({ handleChange, errorFields }) {
  return (
    // <Card id="basic-info" sx={{ overflow: "visible" }}>
    <>
      <MDBox p={3}>
        <MDTypography variant="h5">Communicating with Equifax</MDTypography>
      </MDBox>
      <Grid container justifyContent={'center'}>
      <p>Getting info from Equifax...</p>
      </Grid>
     
    </>
    // </Card>
  );
}

// function Identification({ handleChange, errorFields }) {
//   return (
//     // <Card id="basic-info" sx={{ overflow: "visible" }}>
//     <>
//       <MDBox p={3}>
//         <MDTypography variant="h5">Identity Verification for Equifax Credit Check</MDTypography>
//       </MDBox>
//       <MDBox component="form" mt={2} pb={3} px={3}>
//         <Grid container spacing={5}>
//           <Grid item xs={12}>
//             <FormField
//               required
//               error={errorFields.indexOf("q1") !== -1}
//               name="q1"
//               onBlur={handleChange}
//               label="(1) Where was your credit card ending with 0009 issued at?"
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <FormField
//               required
//               error={errorFields.indexOf("q2") !== -1}
//               name="q2"
//               onBlur={handleChange}
//               label="(2) Which car brand did you finance in year 2020? "
//             />
//           </Grid>
//           <Grid item xs={12}>
//             <FormField
//               required
//               error={errorFields.indexOf("q3") !== -1}
//               name="q3"
//               onBlur={handleChange}
//               label="(3) Where was your credit card ending with 0009 issued at?"
//             />
//           </Grid>
//         </Grid>
//       </MDBox>
//     </>
//     // </Card>
//   );
// }



Identification.propTypes = {
  handleChange: PropTypes.func.isRequired,
  errorFields: PropTypes.arrayOf(PropTypes.string).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
};

export default Identification;
