/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @material-ui core components
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material/";
import Autocomplete from "@mui/material/Autocomplete";
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import { useState } from "react";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

const provinceList = [
  { short_name: "AB", long_name: "Alberta" },
  { short_name: "BC", long_name: "British Columbia" },
  { short_name: "MB", long_name: "Manitoba" },
  { short_name: "NB", long_name: "New Brunswick" },
  { short_name: "NL", long_name: "Newfoundland" },
  { short_name: "NS", long_name: "Nova Scotia" },
  { short_name: "ON", long_name: "Ontario" },
  { short_name: "PE", long_name: "Prince Edward Island" },
  { short_name: "QC", long_name: "Quebec" },
  { short_name: "SK", long_name: "Saskatchewan" },
  { short_name: "NU", long_name: "Nunavut" },
];
function AddressInfo({ handleChange, handleAddressChange, errorFields, data }) {
  const [place, setPlace] = useState("");
  const [loading, setLoading] = useState(false);
  const [manual, setManual] = useState(data.manual);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: "AIzaSyD8vPAQuwcaK57iDF5VJezhDs594rZUL1k",
  });

  // useEffect(() => {
  //   // fetch place details for the first element in placePredictions array
  //   if (placePredictions.length)
  //     placesService?.getDetails(
  //       {
  //         placeId: placePredictions[0].place_id,
  //       },
  //       (placeDetails) => {
  //         console.log("placeDetails", placeDetails);
  //       }
  //     );
  // }, [placePredictions]);

  function getPlaceDetails(placeId) {
    if (!placeId) return;
    setLoading(true);
    placesService?.getDetails(
      {
        placeId,
      },
      (placeDetails) => {
        console.log("got placeDetails", placeDetails);

        let streetNumber;
        let streetName;
        let city;
        let city2;
        let province;
        let postalCode;
        let country;
        placeDetails.address_components.forEach((placeItem) => {
          if (placeItem.types[0] === "street_number")
            streetNumber = placeItem.short_name;
          if (placeItem.types[0] === "route") streetName = placeItem.short_name;
          if (placeItem.types[0] === "locality") city = placeItem.short_name;
          if (placeItem.types[0] === "administrative_area_level_3")
            city2 = placeItem.short_name;
          if (placeItem.types[0] === "administrative_area_level_1")
            province = placeItem.short_name;
          if (placeItem.types[0] === "country") country = placeItem.long_name;
          if (placeItem.types[0] === "postal_code")
            postalCode = placeItem.short_name;
          else if (placeItem.types[0] === "postal_code_prefix")
            postalCode = placeItem.short_name;
        });
        const newPlace = {
          street: `${streetNumber ?? ""} ${streetName}`.trim(),
          city: city ?? city2 ?? "",
          province,
          postalCode,
          country,
        };
        setPlace(newPlace);
        handleAddressChange({
          address: placeDetails.formatted_address,
          street: newPlace.street,
          city,
          province,
          postalCode,
          country,
        });
        setLoading(false);
      }
    );
  }

  if (data && data.street && place === "")
    setPlace({
      street: data.street,
      city: data.city,
      province: data.province,
      postalCode: data.postalCode,
      country: data.country,
    });

  return (
    <MDBox component="form" pb={3}>
      <Grid container spacing={3} pb={3}>
        <Grid item xs={12}>
          {manual ? (
            <MDButton
              onClick={() => setManual(0)}
              size="small"
              variant="contained"
              color="info"
            >
              Auto-complete Address
            </MDButton>
          ) : (
            <Autocomplete
              onChange={(event, newValue) => {
                if (newValue != null) getPlaceDetails(newValue?.place_id);
              }}
              loading={isPlacePredictionsLoading}
              options={placePredictions}
              getOptionLabel={(item) => item?.description}
              renderInput={(params) => (
                <FormField
                  {...params}
                  required
                  defaultValue={data.address}
                  error={errorFields.indexOf("address") !== -1 || manual}
                  onChange={(evt) => {
                    handleChange(evt);
                    getPlacePredictions({
                      input: evt.target.value,
                      componentRestrictions: { country: "ca" },
                    });
                  }}
                  name="address"
                  label="Search Address (without unit)"
                  placeholder=""
                  helperText={
                    !place && (
                      <MDButton
                        onClick={() => setManual(1)}
                        size="small"
                        variant="text"
                        color="info"
                      >
                        Enter Manually
                      </MDButton>
                    )
                  }
                />
              )}
            />
          )}
        </Grid>
      </Grid>
      {loading && (
        <Grid container justifyContent={"center"} spacing={3} py={3}>
          <CircularProgress />
        </Grid>
      )}
      {(manual || place) && !loading && (
        <Grid container spacing={3} py={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              defaultValue={data.unit}
              name="unit"
              onChange={handleChange}
              label="Unit"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              required
              error={errorFields.indexOf("street") !== -1}
              name="street"
              defaultValue={place.street}
              onChange={handleChange}
              label="Street"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              required
              error={errorFields.indexOf("city") !== -1}
              name="city"
              defaultValue={place.city}
              onChange={handleChange}
              label="City"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormControl
              required
              variant="standard"
              sx={{ pt: 1, minWidth: 120 }}
              fullWidth
            >
              <InputLabel id="province-label">Province</InputLabel>
              <Select
                onChange={handleChange}
                labelId="province-label"
                error={errorFields.indexOf("province") !== -1}
                name="province"
                label="Province"
                defaultValue={place.province}
              >
                {provinceList.map((item) => (
                  <MenuItem value={item.short_name}>{item.long_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              required
              defaultValue={place.postalCode}
              error={errorFields.indexOf("postalCode") !== -1}
              name="postalCode"
              onChange={handleChange}
              label="Postal Code"
              placeholder=""
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              required
              defaultValue={place.country ?? "Canada"}
              error={errorFields.indexOf("country") !== -1}
              name="country"
              onChange={handleChange}
              label="Country"
              placeholder=""
            />
          </Grid>
        </Grid>
      )}
    </MDBox>
  );
}
AddressInfo.defaultProps = {
  data: {},
};
AddressInfo.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleAddressChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object,
  errorFields: PropTypes.arrayOf(PropTypes.string).isRequired,
};
export default AddressInfo;
